import { useMemo } from 'react';
import { match } from '@/types/match';
import { DraftOrderSelection } from '../../models';

export const useOrderRequestAggregation = (selection: DraftOrderSelection) => {
	const identities = useMemo(() => {
		// We only care about draft orders because that is what is
		// used to restore the selection
		const relevantOrders = Object.values(selection.draft_orders).filter(
			order => order.status === 'Draft'
		);
		return relevantOrders.reduce(
			(acc, order) => {
				order.items.forEach(item => {
					if (item.buyable.type === 'External' && item.buyable.identity) {
						match(item.buyable.identity, {
							Part: part => (acc[part.mpn] = true),
							Sku: () => {
								/** nothing */
							}
						});
					}
				});

				return acc;
			},
			{} as Record<string, true>
		);
	}, [selection]);

	const { totalItems, totalPrice, totalSuppliers } = useMemo(() => {
		const uniqueSupplierIds: string[] = [];

		const totalSuppliers = Object.values(selection.draft_orders).reduce((accu, order) => {
			if (!uniqueSupplierIds.includes(order.vendor.Partner.id)) {
				uniqueSupplierIds.push(order.vendor.Partner.id);
				return accu + 1;
			}
			return accu;
		}, 0);

		return {
			totalSuppliers,
			...Object.values(selection.draft_orders)
				.filter(order => {
					if (typeof order.status === 'object' && 'Cancelled' in order.status) {
						return false;
					}

					if (order.status === 'Finalised') {
						return false;
					}

					return true;
				})
				.reduce(
					(acc, order) => {
						let price = 0;
						let items = 0;

						for (const item of order.items) {
							if (typeof item.status === 'object' && 'Rejected' in item.status) {
								continue;
							}

							items += item.quantity;
							price += item.quantity * (item.price ?? 0);
						}

						acc.totalItems += items;
						acc.totalPrice += price;
						return acc;
					},
					{ totalItems: 0, totalPrice: 0 }
				)
		};
	}, [selection]);

	return {
		totalItems,
		totalPrice,
		totalSuppliers,
		identities
	};
};
