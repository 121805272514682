import { signIn, signOut } from '@/app/utils/auth';
import { ApiError } from '@/sdk/lib';
import { getConfig } from '@/sdk/react';
import { mutations } from '@/sdk/react/mutations';
import { InheritableElementProps } from '@/types/utilties';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, Select, Skeleton } from '@mantine/core';
import { business_network_model } from '@partly/core-server-client';
import * as Sentry from '@sentry/browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

const businessSchema = yup.object({
	business: yup.string().required().label('Business')
});

export type BusinessFormData = yup.InferType<typeof businessSchema>;

export type BusinessFormProps = Omit<
	InheritableElementProps<'form', { businesses: business_network_model.BusinessModel[] }>,
	'noValidate'
>;

export const BusinessForm = ({ businesses, ...rest }: BusinessFormProps) => {
	const queryClient = useQueryClient();
	const { mutateAsync, isPending, error } = useMutation({
		...mutations.users.signin,
		onSuccess: async result => {
			signIn(result.access_token, result.refresh_token);
			await queryClient.resetQueries();
		}
	});
	const { handleSubmit, control } = useForm<BusinessFormData>({
		defaultValues: {
			business: businesses.at(0)?.id
		},
		resolver: yupResolver(businessSchema)
	});

	const onSubmit = async ({ business }: BusinessFormData) => {
		const token = getConfig().userToken?.token;
		if (!token) {
			return;
		}
		try {
			await mutateAsync({
				access_token: token,
				authorization_issuer: '/api/v1/repairers.verify',
				authorization_parameters: {
					repairer_id: business
				}
			});
		} catch (error) {
			if (error instanceof ApiError && error.status >= 400 && error.status < 500) {
				// Debug capture the expected errors for login endpoint
				Sentry.captureException(error, {
					level: 'debug'
				});
			} else {
				console.warn('Unexpected error', error);
				// Catch any unhandled error and send to Sentry
				Sentry.captureException(error);
			}
		}
	};

	const onBack = async () => {
		signOut();
		await queryClient.resetQueries();
	};

	useEffect(() => {
		if (businesses.length === 1) {
			onSubmit({ business: businesses[0].id });
		}
	}, [businesses]);

	if (isPending) {
		return <Skeleton className={rest.className} />;
	}

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} noValidate {...rest}>
				<div className="space-y-4">
					<Controller
						control={control}
						name="business"
						render={({ field: { onChange, value, ...controlRest } }) => (
							<Select
								data-field="business"
								label="Business"
								value={value ?? null}
								data={businesses.map(({ id, name }) => ({ value: id, label: name }))}
								placeholder="Select business"
								clearable
								onChange={onChange}
								{...controlRest}
							/>
						)}
					/>
				</div>
				<Button className="w-full mt-8" type="submit">
					Continue
				</Button>

				<div className="flex w-full items-center justify-center mt-4">
					<button type="button" className="text-sm text-gray-500" onClick={onBack}>
						Go back to sign in
					</button>
				</div>
			</form>
			{error && (
				<Alert color="red" className="mt-4" title="Sign in error">
					Invalid business
				</Alert>
			)}
		</>
	);
};
