import {
	JobPartOfferSearchRequest,
	JobPartOfferSearchResponse
} from '@/app/features/supply/models';
import { createOfferItemsRequest, enrichJobParts } from '@/app/features/supply/utils';
import { StorefrontError } from '@/sdk/lib';
import { getConfig, intercept } from '@/sdk/react';
import { getApi } from '@/sdk/react/utils/helpers';
import { coreSdk } from '@/sdk/reflect';
import { supply_search } from '@partly/core-server-client';
import { queryOptions } from '@tanstack/react-query';

/**
 * A query to load parts and offers for a job.
 * Why custom query? There are dependencies between the parts and offers
 * so for simplicity with suspense we can load them together.
 */
export const jobPartOfferSearch = (payload: JobPartOfferSearchRequest) =>
	queryOptions<JobPartOfferSearchResponse, supply_search.SellablesSearchError | StorefrontError>({
		queryKey: ['offers', 'search', payload],
		queryFn: () =>
			intercept(async () => {
				const [sdk, headers] = coreSdk(getConfig());

				// Step 1:
				// Load the job parts
				const { data, error } = await getApi().jobs.listParts({ jobId: payload.job_id });
				if (error) {
					throw error;
				}

				// Step 2:
				// Enrich the job parts with transient ids and create the
				// items request
				const jobParts = enrichJobParts(data.parts);
				const items = createOfferItemsRequest(jobParts);

				// Step 3:
				// Search for any offers that match the job parts
				const result = await sdk.offers.search(
					{
						items,
						deliver_before: payload.deliver_before?.toISOString(),
						repairer_site_id: payload.repairerSiteId
					},
					headers as { authorization: string } // ew fix
				);

				if (result.is_err()) {
					throw result.unwrap_err();
				}

				return { job_parts: jobParts, ...result.unwrap_ok() };
			})
	});
