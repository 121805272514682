import { formatPrice } from '@/app/utils/currency';
import { Button, Card, Tooltip } from '@mantine/core';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ConditionBadge } from '../condition-badge';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { formatOfferPrice } from '../../utils';
import { Badge } from '@/app/atoms/badge';
import { offers_search, supply_search } from '@partly/core-server-client';

interface AssemblyOfferCardProps {
	assembly: offers_search.Assembly;
	business: supply_search.Business;
	isSelected: boolean;
	deliverBefore: Date;
	onSelectChange(isSelected: boolean): void;
}

export const AssemblyOfferCard = ({
	assembly,
	business,
	deliverBefore,
	onSelectChange,
	isSelected
}: AssemblyOfferCardProps) => {
	const handleSelectOffer = () => {
		onSelectChange(!isSelected);
	};

	return (
		<Card
			className="p-6 ring-gray-200 overflow-visible min-h-[186px] flex-col cursor-pointer ring-1 w-full flex gap-3 items-start rounded-md"
			aria-checked={isSelected}
			role="checkbox"
			onClick={handleSelectOffer}
		>
			<div className="flex w-full justify-between font-semibold">
				<b className="font-semibold">{business.name}</b>
				<span>{formatPrice(formatOfferPrice(assembly.sellable.price))} </span>
			</div>
			<div className="flex w-full text-xs justify-between text-gray-700">
				<FormattedShippingTime
					deliverBefore={deliverBefore}
					shippingTime={assembly.sellable.shipping_time || null}
				/>
				{assembly.sellable.entity.condition ? (
					<ConditionBadge condition={assembly.sellable.entity.condition} />
				) : null}
			</div>
			{assembly ? (
				<div className="flex  gap-2 w-full justify-end items-center text-blue-600">
					<Badge variant="blue" size="small" rounded>
						Assembly
					</Badge>
					<Tooltip
						label={assembly?.children
							.map(child => child.entity.name)
							.toString()
							.replaceAll(',', ', ')}
					>
						<span className="text-xs">{assembly.children.length} parts total </span>
					</Tooltip>
				</div>
			) : null}
			<Button
				className="px-16 mt-auto"
				variant={isSelected ? 'filled' : 'outline'}
				leftIcon={isSelected && <CheckCircleIcon width={16} />}
			>
				{isSelected ? 'Selected' : 'Select'}
			</Button>
		</Card>
	);
};
