import { localiseCondition } from '@/app/utils/grade';
import { TagIcon } from '@heroicons/react/24/outline';
import { lookup } from '@partly/core-server-client';

interface ConditionBadgeProps {
	condition: lookup.SellableCondition;
}

export const ConditionBadge = ({ condition }: ConditionBadgeProps) => (
	<div className="flex items-center text-inherit gap-1 ">
		<TagIcon width={17} />
		{localiseCondition(condition)}
	</div>
);
