import { client as coreClient } from '@partly/core-server-client';
import { ApiConfig } from '../lib';
import {
	context,
	draft_order_handler,
	Err,
	order_handler,
	client as repairClient,
	Result
} from './reflect';
import { ResourceMap } from './resource';
import { transform } from './transform';
import { RepairerResponse } from './types';

function createRepairerHeaders(apiConfig: ApiConfig): context.RepairerServerHeaders {
	const authorization = apiConfig.userToken ? `Bearer ${apiConfig.userToken.token}` : null;

	return {
		'partly-storefront-key': apiConfig.webKey,
		'partly-accept-currency': apiConfig.currencyCode ?? null,
		'partly-uvdb-region-id': apiConfig.vehicle?.uvdbRegionId ?? null,
		'partly-organization-id': apiConfig.organizationId ?? null,
		authorization
	};
}

function createRepairClient(config: ApiConfig) {
	const headers = createRepairerHeaders(config);
	const api = repairClient(config.basePath);
	return [api, headers] as const;
}

function createCoreClient(config: ApiConfig) {
	const { authorization } = createRepairerHeaders(config);
	const api = coreClient(config.corePath);
	return [api, { authorization }] as const;
}

export function repairSdk(config: ApiConfig) {
	const [client, headers] = createRepairClient(config);

	return {
		draft_orders: {
			list: async (
				payload: draft_order_handler.DraftOrdersListRequest
			): Promise<
				RepairerResponse<
					draft_order_handler.exp.DraftOrderListResponse,
					transform.draft_orders.DraftOrderTransformError
				>
			> => {
				const response = await client.jobs.draft_orders.list(payload, headers);
				return to_response(response, data => {
					const resources = ResourceMap.create(data);
					return transform.draft_orders.list(data.payload.items, resources);
				});
			},
			ingest: async (
				payload: draft_order_handler.DraftOrderIngestRequest
			): Promise<
				RepairerResponse<
					draft_order_handler.exp.DraftOrderListResponse,
					transform.draft_orders.DraftOrderTransformError
				>
			> => {
				const response = await client.jobs.draft_orders.ingest(payload, headers);
				return to_response(response, data => {
					const resources = ResourceMap.create(data);
					return transform.draft_orders.list(data.payload.items, resources);
				});
			}
		},
		orders: {
			list: async (
				payload: order_handler.OrdersListRequest
			): Promise<
				RepairerResponse<order_handler.exp.OrdersListResponse, transform.order.OrderTransformError>
			> => {
				const response = await client.jobs.orders.list(payload, headers);
				return to_response(response, data => {
					const resources = ResourceMap.create(data);
					return transform.order.list(data.payload.items, resources);
				});
			},
			update: async (
				payload: order_handler.OrdersUpdateRequest
			): Promise<
				RepairerResponse<
					order_handler.exp.OrdersUpdateResponse,
					transform.order.OrderTransformError
				>
			> => {
				const response = await client.jobs.orders.update(payload, headers);
				return to_response(response, data => {
					const resources = ResourceMap.create(data);
					const order = transform.order.order(data.payload.item, resources);
					if (order.is_err()) {
						return new Result({ err: order.unwrap_err() });
					}

					return new Result({ ok: { order: order.unwrap_ok() } });
				});
			}
		}
	};
}

export function coreSdk(config: ApiConfig) {
	return createCoreClient(config);
}

/**
 * R: Response type
 * T: Transformed response type
 * N: Network Error type
 * A: Transform Error type
 */
function to_response<R, T, N, A>(
	response: Result<R, Err<N>>,
	callback: (data: R) => Result<T, A>
): RepairerResponse<T, A | N> {
	if (response.is_err()) {
		const error = response.unwrap_err().unwrap();
		return { data: null, error };
	}

	const data = callback(response.unwrap_ok());
	if (data.is_err()) {
		const error = data.unwrap_err();
		return { data: null, error };
	}

	return {
		data: data.unwrap_ok(),
		error: null
	};
}
