import { isDefined } from '@/app/utils/common';
import { formatPartContext } from '@/app/utils/part';
import { draft_order, part_slot, supply } from '@/sdk/reflect/reflect';
import { lookup, offers_search, supply_search } from '@partly/core-server-client';
import { GetJobSupplyRecommendationsResult, JobPart, SupplyItemOffer } from '@sdk/lib';
import { UseFormReturn } from 'react-hook-form';
import { v4 as generateId } from 'uuid';
import {
	DraftOrderSelection,
	EnrichedJobPart,
	JobPartOfferSearchResponse,
	JobPartRecommendedOffers,
	OfferId,
	OfferSelection,
	OrderPartSupplyOffer,
	PartOfferAggregation
} from './models';
import { applyOfferSelection, restoreOrderSelection } from './order-request';

const setNestedValue = (obj: Record<string, any>, keys: string[], value: any) => {
	let current = obj;
	// Iterate over all the keys except the last one
	for (let i = 0; i < keys.length - 1; i++) {
		// If the key doesn't exist, create it as an empty object
		if (!current[keys[i]]) {
			current[keys[i]] = {};
		}
		current = current[keys[i]]; // Move to the next level
	}
	// Set the value at the deepest level
	current[keys[keys.length - 1]] = value;
};

export const createSelection = (
	draft_orders: draft_order.exp.DraftOrder[],
	partOfferAggregations: PartOfferAggregation[],
	jobPartRecommendedOffers: JobPartRecommendedOffers | null,
	repairer_site_id: string
): DraftOrderSelection => {
	// Creating a new initial selection.
	const selection: DraftOrderSelection = {
		delivery_date: new Date(),
		deliver_before: new Date(),
		repairer_site_id,
		draft_orders: {}
	};

	// restore any saved / existing draft order
	if (draft_orders.length > 0) {
		return restoreOrderSelection(selection, draft_orders);
	}

	if (!jobPartRecommendedOffers) {
		return selection;
	}

	// creates a draft order by auto-selecting supplies for job parts
	const newSelection = { ...selection };

	const form = {
		setValue: (key: keyof DraftOrderSelection, value: any) => {
			const keys = key.split('.');

			setNestedValue(newSelection, keys, value);
		},

		getValues: () => newSelection
	} as UseFormReturn<DraftOrderSelection>;

	partOfferAggregations.forEach(({ jobPart }) => {
		const recommendedOffers = jobPartRecommendedOffers[jobPart.transientId];
		if (recommendedOffers) {
			recommendedOffers.forEach(recommendedOffer =>
				applyOfferSelection(jobPart, recommendedOffer.offer, form)
			);
		}
	});

	return newSelection;
};

export const createPartSelectionContext = (
	ctx: part_slot.exp.PartSelectionContext | null | undefined
): part_slot.PartSelectionContext | null => {
	if (!ctx) {
		return null;
	}

	const newContext: part_slot.PartSelectionContext = {
		description: ctx.description,
		gapc_brand_id: ctx.gapc_brand?.id ?? undefined,
		gapc_part_type_id: ctx.gapc_part_type?.id ?? undefined,
		gapc_position_id: ctx.gapc_position?.id ?? undefined,
		mpn: ctx.mpn ?? undefined,
		hcas: ctx.hcas ?? undefined
	};

	return newContext;
};

export const createPartSelectionContexts = (
	context: part_slot.exp.PartSelectionContexts | null | undefined
): part_slot.PartSelectionContexts | null => {
	if (!context) {
		return null;
	}

	return context.map(ctx => createPartSelectionContext(ctx)).filter(isDefined);
};

export const compareOfferIds = (a: SupplyItemOffer, b: supply.SupplyItemOffer) => {
	if (a.type === 'Product' && b.type === 'Product') {
		return a.offerId === b.offer_id;
	}

	if (a.type === 'Kit' && b.type === 'Kit') {
		if (a.offerIds.length !== b.offer_ids.length) {
			return false;
		}

		// typescript forgets that b is a Kit here
		return a.offerIds.every(offerId => b.type === 'Kit' && b.offer_ids.includes(offerId));
	}

	return false;
};

export const createJobPartName = (part: JobPart): string => {
	const partName = formatPartContext(
		part.partSlot?.gapcPartType ?? null,
		part.partSlot?.gapcPosition ?? null,
		part.description,
		part.mpn
	);

	if (!partName) {
		return 'N/A';
	}

	return partName;
};

export const sortOffers = (
	offers: OrderPartSupplyOffer[],
	recommendedOfferId: string | undefined
) => {
	const recommendedOffer = offers.find(offer => offer.id === recommendedOfferId);
	const rest = offers.filter(offer => offer.id !== recommendedOfferId);

	return [recommendedOffer, ...rest.sort((a, b) => a.price.price - b.price.price)].filter(
		isDefined
	);
};

/**
 * TODO supply recommendation algorithm needs to support kits, as a kit can fulfill multiple job parts
 */
export const anyOfferFulfillsMultipleJobParts = (
	recommendationData: GetJobSupplyRecommendationsResult
): boolean => {
	for (const offerId in recommendationData.offers) {
		const offer = recommendationData.offers[offerId];
		if (offer.gapcParts.length > 1) {
			return true;
		}
	}

	return false;
};

export const getBusiness = (offer: offers_search.Offer) => {
	const { type } = offer;

	if (type === 'group') {
		// TODO: handle groups
		return null;
	} else if (type === 'single') {
		const singleOffer = offer as offers_search.SellableOffer;
		return singleOffer.business;
	}
	return null;
};

export const getOfferSellable = (offer: offers_search.Offer) => {
	const { type } = offer;

	if (type === 'group') {
		// TODO: handle groups
		return null;
	} else if (type === 'single') {
		const singleOffer = offer as offers_search.SellableOffer;

		if (singleOffer.payload.kind === 'assembly') {
			return singleOffer.payload.sellable;
		}
		return singleOffer.payload;
	}
};

export const getOffersPerCondition = (offers: offers_search.Offer[], condition: 'new' | 'used') => {
	// TODO handle groups
	return offers
		.filter(offer => {
			const sellable = getOfferSellable(offer);

			if (sellable) {
				return condition === 'new'
					? sellable.entity.condition === 'new'
					: sellable.entity.condition !== 'new';
			}
			return false;
		})
		.sort((a, b) =>
			(a as offers_search.SellableOffer).business.name.localeCompare(
				(b as offers_search.SellableOffer).business.name
			)
		);
};

export const enrichJobParts = (jobParts: JobPart[]): EnrichedJobPart[] =>
	jobParts.map(part => ({ transientId: generateId(), ...part }));

export const createOfferItemsRequest = (jobParts: EnrichedJobPart[]) => {
	return jobParts.flatMap(part => {
		if (part.gapcBrand === null) {
			return [];
		}

		return {
			job_part_id: part.transientId,
			job_part_item: {
				single: {
					gapc_part_identity: {
						brand_id: part.gapcBrand.id,
						mpn: part.mpn
					},
					quantity: part.quantity
				}
			}
		};
	});
};

export const findOfferById = (
	offers: offers_search.Offer[],
	id: OfferId
): offers_search.Offer | null => offers.find(offer => offer.id === id) || null;

export const getRecommendedOffers = (
	offerData: JobPartOfferSearchResponse,
	recommendationType: keyof offers_search.RecommendedBaskets | null
) => {
	if (!recommendationType) {
		return null;
	}

	const { matches, recommendations, offers } = offerData;

	const recommendedMatches = recommendations.baskets[recommendationType].map(recommendation => {
		return { quantity: recommendation.quantity, match: matches[recommendation.match_index] };
	});

	return recommendedMatches
		.map(recommendedMatch => ({
			quantity: recommendedMatch.quantity,
			jobPartId: recommendedMatch.match.job_part_id,
			offerId: recommendedMatch.match.offer_id,
			offer: findOfferById(offers as offers_search.Offer[], recommendedMatch.match.offer_id)
		}))
		.reduce((accu, recommendedOffer) => {
			if (!recommendedOffer.offer) {
				return accu;
			}

			if (!accu[recommendedOffer.jobPartId]) {
				accu[recommendedOffer.jobPartId] = [];
			}

			accu[recommendedOffer.jobPartId].push({
				offer: recommendedOffer.offer,
				quantity: recommendedOffer.quantity
			});
			return accu;
		}, {} as JobPartRecommendedOffers);
};

export const formatOfferPrice = (price: string | number | null) =>
	price ? Number(price) * 1000 : 0;

export const getAssembly = (offer: offers_search.Offer) => {
	if (offer.type === 'group') {
		// TODO: handle groups
		return null;
	}

	const singleOffer = offer as offers_search.SellableOffer;

	if (singleOffer.payload.kind === 'assembly') {
		return singleOffer.payload;
	}
	return null;
};

export const getSelectedJobPartOffers = (
	offerSelection: OfferSelection,
	partOfferAggregation: PartOfferAggregation
) => {
	const selectedOffers: offers_search.Offer[] = [];

	for (const offerId of offerSelection) {
		const offer = partOfferAggregation.offers.find(o => o.id === offerId);

		if (offer) {
			selectedOffers.push(offer);
		}
	}
	return selectedOffers;
};

/**
 * Returns aggregated values for selected offers such as totalPrice etc.
 */
export const getOffersSummary = (
	offerSelection: OfferSelection,
	partOfferAggregation: PartOfferAggregation
) => {
	const selectionAggregation: {
		totalPrice: number;
		totalCount: number;
		suppliers: supply_search.Business[];
		conditionsPerSupplier: Record<
			string,
			{ condition?: lookup.SellableCondition | null; isAssembly: boolean }[]
		>;
		shippingTime: Required<Omit<supply_search.ShippingTime, 'eta' | 'store_address_id'>> | null;
	} = {
		totalCount: 0,
		totalPrice: 0,
		suppliers: [],
		conditionsPerSupplier: {},
		shippingTime: null
	};

	const potentialAggregation: {
		minPrice: number;
		supplyOptionsCount: number;
	} = {
		minPrice: Number.MAX_VALUE,
		supplyOptionsCount: 0
	};

	const { quantity } = partOfferAggregation.jobPart;

	const selectedOffers = getSelectedJobPartOffers(offerSelection, partOfferAggregation);

	if (selectedOffers.length === 0) {
		// No offers selected
		partOfferAggregation.offers.forEach(offer => {
			const sellable = getOfferSellable(offer);

			if (!sellable) {
				// TODO: handle groups
				return null;
			}

			const { price } = sellable;

			if (formatOfferPrice(price) < potentialAggregation.minPrice / quantity) {
				potentialAggregation.minPrice = formatOfferPrice(price) * quantity;
			}
			potentialAggregation.supplyOptionsCount += 1;
		});
	} else {
		// Offers selected
		const uniqueSupplierIds: string[] = [];

		selectedOffers.forEach(offer => {
			selectionAggregation.totalCount += 1;
			const sellable = getOfferSellable(offer);
			const business = getBusiness(offer);

			if (!sellable || !business) {
				// TODO: handle groups
				return null;
			}

			const { price, shipping_time, entity } = sellable;

			selectionAggregation.totalPrice += formatOfferPrice(price) * quantity;

			if (!selectionAggregation.shippingTime) {
				selectionAggregation.shippingTime = shipping_time || null;
			} else if (
				(shipping_time &&
					shipping_time.arrival_time &&
					shipping_time.arrival_time > selectionAggregation.shippingTime!.arrival_time!) ||
				(shipping_time?.business_days &&
					shipping_time.business_days > selectionAggregation.shippingTime!.business_days!)
			) {
				selectionAggregation.shippingTime = shipping_time;
			}

			if (!uniqueSupplierIds.includes(business.id)) {
				uniqueSupplierIds.push(business.id);
				selectionAggregation.suppliers.push(business);

				if (!selectionAggregation.conditionsPerSupplier[business.id]) {
					selectionAggregation.conditionsPerSupplier[business.id] = [];
				}

				selectionAggregation.conditionsPerSupplier[business.id].push({
					condition: entity.condition,
					isAssembly: Boolean(getAssembly(offer))
				});
			}
		});

		// Deduplicate conditions per supplier
		selectionAggregation.conditionsPerSupplier = Object.keys(
			selectionAggregation.conditionsPerSupplier
		).reduce(
			(accu, supplierId) => {
				const uniqueConditionsPerSupplier: string[] = [];
				const conditionsPerSupplier = selectionAggregation.conditionsPerSupplier[supplierId];

				if (!accu[supplierId]) {
					accu[supplierId] = [];
				}

				conditionsPerSupplier.forEach(({ condition, isAssembly }) => {
					const key = `${JSON.stringify(condition)}${isAssembly}`;

					if (!uniqueConditionsPerSupplier.includes(key)) {
						uniqueConditionsPerSupplier.push(key);

						accu[supplierId].push({ condition, isAssembly });
					}
				});
				return accu;
			},
			{} as Record<string, { condition?: lookup.SellableCondition | null; isAssembly: boolean }[]>
		);
	}

	return {
		selected: selectedOffers.length > 0 ? selectionAggregation : null,
		potential:
			selectedOffers.length === 0 && partOfferAggregation.offers.length > 0
				? potentialAggregation
				: null
	};
};
