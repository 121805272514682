import { ShippingTime } from '@/sdk/lib';
import { daysToHumanString, secondsToHumanTime } from '@/app/utils/date';
import { isNil } from 'lodash-es';
import { format } from 'date-fns';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ClockIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';

interface FormattedShippingTimeProps {
	deliverBefore: Date;
	shippingTime: Omit<ShippingTime, 'eta'> | null;
	className?: string;
}

export const FormattedShippingTime = ({
	shippingTime,
	deliverBefore,
	className
}: FormattedShippingTimeProps) => {
	if (!shippingTime) {
		return '--';
	}

	const { arrival_time, business_days } = shippingTime;

	const byTime = arrival_time ? `, by ${secondsToHumanTime(arrival_time)}` : '';
	const days = !isNil(business_days) ? daysToHumanString(business_days) : null;

	if (arrival_time && deliverBefore && deliverBefore.getTime() > arrival_time) {
		return (
			<div className={clsx('flex gap-1', className)}>
				<CheckIcon color="green" width={17} /> Arrives on time by{' '}
				{format(deliverBefore, 'dd/MM/yyyy')}
			</div>
		);
	}

	return days ? (
		<div className={clsx('flex gap-1', className)}>
			<ClockIcon className="text-gray-400" width={17} /> {days + byTime}
		</div>
	) : (
		<span>Available via back order</span>
	);
};
