import { BusinessForm } from '@/app/features/user/components/business-form';
import { OrganizationForm } from '@/app/features/user/components/organization-form';
import { SignInForm } from '@/app/features/user/components/sign-in-form';
import { queries } from '@/sdk/react/queries';
import Logo from '@assets/logo.png';
import { withRedirectIfSignedIn } from '@hoc/with-access';
import { Skeleton } from '@mantine/core';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';

const SignInPage = () => {
	const [
		{ data: orgsData, isLoading: isOrgsLoading },
		{ data: businessesData, isLoading: isBusinessesLoading }
	] = useQueries({
		queries: [
			{
				...queries.organizations.list(),
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				retry: 0
			},
			{
				...queries.businesses.list({ profiles: [{ type: 'repairer' }] }),
				staleTime: Infinity,
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				retry: 0
			}
		]
	});

	const stage = useMemo(() => {
		if (businessesData) {
			return { kind: 'business' as const, businesses: businessesData.items };
		}
		if (orgsData) {
			return { kind: 'organization' as const, organizations: orgsData.items };
		}
		return { kind: 'user' as const };
	}, [orgsData, businessesData]);

	const isLoading = isOrgsLoading || isBusinessesLoading;

	return (
		<>
			<div className="flex flex-col justify-center items-center flex-1 min-h-full py-12 px-4 bg-gray-50">
				<div className="px-4 py-8 m-4 bg-white border rounded-md shadow w-full max-w-md">
					<div>
						<img className="w-44 h-auto mx-auto" src={Logo} alt="Your Company" />
						<h2 className="mt-6 text-2xl font-bold leading-9 tracking-tight text-center text-gray-900">
							Sign in to your account
						</h2>
					</div>

					{isLoading ? (
						<Skeleton className="mt-10 w-full h-8" />
					) : stage.kind === 'user' ? (
						<SignInForm className="mt-10" />
					) : stage.kind === 'organization' ? (
						<OrganizationForm className="mt-10" organizations={stage.organizations} />
					) : (
						<BusinessForm className="mt-10" businesses={stage.businesses} />
					)}
				</div>
			</div>
		</>
	);
};

export default withRedirectIfSignedIn(SignInPage);
