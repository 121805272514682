import { auth } from '@partly/core-server-client';

// todo (vincent) core has not unified error types so i had to make one to handle all possible case for unauthorized
export type CoreServerMaybeUnauthorizedError = { unauthorized?: auth.AuthError };

export type Interceptors = {
	onError: (error: unknown) => Promise<boolean>;
};

let interceptors: Interceptors | undefined;

export const getInterceptors = () => {
	if (!interceptors) {
		throw new Error('Api interceptor is not set');
	}

	return Object.freeze({ ...interceptors });
};

export const setInterceptors = (interceptor: Interceptors) => {
	interceptors = interceptor;
};

export const updateInterceptors = (interceptor: Partial<Interceptors>) => {
	if (!interceptors) {
		throw new Error('Cannot update api interceptor when it is not set');
	}

	Object.assign(interceptors, interceptor);
};

export const intercept = async <T>(method: () => Promise<T>) => {
	const interceptors = getInterceptors();
	try {
		const res = await method();
		return res;
	} catch (err) {
		const shouldRetry = await interceptors.onError(err);
		if (shouldRetry) {
			return await method();
		}
		throw err;
	}
};
