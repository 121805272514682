import { EmptyState } from '@/app/atoms/empty-state';
import { tlsx } from '@/app/utils/tw-merge';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { ForwardedRef, forwardRef, HtmlHTMLAttributes, useMemo } from 'react';
import { OfferSelection, PartOfferAggregation } from '../../models';
import { JobPartCard } from '../job-part-card';
import { isDefined } from '@/app/utils/common';
import { flatten } from 'lodash-es';
import { getAssembly } from '../../utils';

interface PartsListSidebarProps extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'onSelect'> {
	partOfferAggregations: PartOfferAggregation[];
	selectedId?: string;
	deliverBefore: Date;
	offerSelection: OfferSelection;
	onSelect(offer: PartOfferAggregation): void;
}

export const PartsListSidebar = forwardRef(
	(
		{
			className,
			partOfferAggregations,
			selectedId,
			deliverBefore,
			offerSelection,
			onSelect,
			...rest
		}: PartsListSidebarProps,
		ref: ForwardedRef<HTMLElement>
	) => {
		// All selected asseblies for all job parts
		const selectedAssemblyOffers = useMemo(
			() =>
				flatten(
					partOfferAggregations.map(({ offers }) =>
						offers
							.filter(offer => offerSelection.has(offer.id))
							.map(offer => (getAssembly(offer) ? { offer, assembly: getAssembly(offer) } : null))
							.filter(isDefined)
					)
				),
			[offerSelection, partOfferAggregations]
		);

		return (
			<aside ref={ref} className={tlsx('w-full overflow-y-auto', className)} {...rest}>
				{partOfferAggregations.length > 0 ? (
					<ul className="px-2 py-0.5 divide-y" aria-label="Parts navigation">
						{partOfferAggregations.map(aggregation => {
							// Check if job part is fulfilled by selected assembly

							const matchingAssemblyOffer = selectedAssemblyOffers.find(({ offer }) =>
								aggregation.offers.some(jobPartOffer => jobPartOffer.id === offer.id)
							);

							const parentAssemblyId = matchingAssemblyOffer
								? matchingAssemblyOffer.assembly!.sellable.entity.id
								: null;

							return (
								<li key={aggregation.jobPart.transientId}>
									<JobPartCard
										deliverBefore={deliverBefore}
										offerSelection={offerSelection}
										isSelected={selectedId === aggregation.jobPart.transientId}
										parentAssemblyId={parentAssemblyId}
										partOfferAggregation={aggregation}
										onSelect={() => onSelect(aggregation)}
									/>
								</li>
							);
						})}
					</ul>
				) : (
					<div>
						<EmptyState className="bg-white">
							<EmptyState.Title>No parts selected</EmptyState.Title>
							<EmptyState.Icon>
								<NoSymbolIcon />
							</EmptyState.Icon>
						</EmptyState>
					</div>
				)}
			</aside>
		);
	}
);
