import { SearchVehiclePayload, SearchVehiclesResult, StorefrontError } from '@sdk/lib';
import { queryOptions } from '@tanstack/react-query';
import { intercept } from '../interceptor';
import { getApi, unwrap } from '../utils/helpers';

export const vehicleQueries = {
	searchVehicles: (payload: SearchVehiclePayload) =>
		queryOptions<SearchVehiclesResult, StorefrontError>({
			queryKey: ['vehicle', 'searchVehicles', payload],
			queryFn: () => intercept(() => unwrap(() => getApi().vehicle.searchVehicles(payload)))
		})
};
