import { AuthState, authBus, onAuthChange, resolveAuth } from '@utils/auth';
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';

type AuthContextProps = {
	/** Whether or not the user is authenticated */
	isAuthenticated: boolean;
	userId?: string;
};

const AuthContext = createContext<AuthContextProps>({ isAuthenticated: false });

export const useAuth = () => useContext(AuthContext);

/*
 * 1. Resolve the current users authentication status.
 *
 * 2. Emulate Reacts `<Suspense />` api, waiting for authentication
 *    to resolve before rendering any children.
 *
 * 3. Subscribe to updates to authentication.
 */
export const createAuthProvider = () =>
	// 1
	resolveAuth().then((initialState: AuthState) => ({
		// 2
		default: (props => {
			const [state, setState] = useState<AuthContextProps>(() => {
				if (initialState.isAuthenticated) {
					return {
						isAuthenticated: initialState.isAuthenticated,
						userId: initialState.userId
					};
				}

				return {
					isAuthenticated: false
				};
			});

			// 3
			useEffect(() => {
				const unsubscribe = authBus.subscribe(onAuthChange, event => {
					if (event.payload.isAuthenticated) {
						setState({
							isAuthenticated: event.payload.isAuthenticated,
							userId: event.payload.userId
						});
						return;
					}

					setState({
						isAuthenticated: event.payload.isAuthenticated
					});
				});
				return () => unsubscribe();
			}, []);

			return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
		}) as React.FC<PropsWithChildren<unknown>>
	}));
