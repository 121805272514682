import { DraftOrderSelection } from '@/app/features/supply/models';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Button } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { offers_search } from '@partly/core-server-client';
import clsx from 'clsx';
import { Controller, UseFormReturn } from 'react-hook-form';

type SupplyHeaderProps = InheritableElementProps<
	'div',
	{
		form: UseFormReturn<DraftOrderSelection>;
		selectedRecommendationType: keyof offers_search.RecommendedBaskets | null;
		onRecommendationSelect(RecommendationType: keyof offers_search.RecommendedBaskets): void;
	}
>;

const SupplyHeader = ({
	form,
	selectedRecommendationType,
	className,
	onRecommendationSelect,
	...rest
}: SupplyHeaderProps) => (
	<div className={tlsx('flex items-center px-6 py-4 justify-between', className)} {...rest}>
		<Controller
			control={form.control}
			name="deliver_before"
			render={({ field }) => (
				<DatePickerInput
					minDate={new Date()}
					valueFormat="DD/MM/YYYY"
					className="flex gap-2 items-center"
					label="Delivery date"
					{...field}
				/>
			)}
		/>
		<div className="flex items-center gap-2">
			<Button.Group className="[&>:not(:last-child)]:border-r-0">
				<Button
					onClick={() => onRecommendationSelect('best')}
					size="sm"
					variant="default"
					// Yuck, but mantine styles get weird.
					className={clsx('!border-r-0', {
						'bg-blue-100 hover:!bg-blue-200': selectedRecommendationType === 'best'
					})}
				>
					Best
				</Button>
				<Button
					onClick={() => onRecommendationSelect('cheapest')}
					size="sm"
					variant="default"
					// Yuck, but mantine styles get weird.
					className={clsx('!border-r-0', {
						'bg-blue-100 hover:!bg-blue-200': selectedRecommendationType === 'cheapest'
					})}
				>
					Cheapest
				</Button>
				<Button
					onClick={() => onRecommendationSelect('fastest')}
					size="sm"
					className={clsx({
						'bg-blue-100 hover:!bg-blue-200': selectedRecommendationType === 'fastest'
					})}
					variant="default"
				>
					Fastest
				</Button>
			</Button.Group>
		</div>
	</div>
);

export default SupplyHeader;
