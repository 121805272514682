import { offers_search } from '@partly/core-server-client';
import { EnrichedJobPart, PartOfferAggregation, TransientPartId } from '../../models';
import { useMemo } from 'react';
import { createJobPartName, findOfferById } from '../../utils';

/**
 * A hook that takes parts/offers and converts them into a part offer
 * aggregation based on matches.
 */
export const usePartOfferAgregations = (
	jobParts: EnrichedJobPart[],
	matches: offers_search.JobPartMatch[],
	offers: offers_search.Offer[]
): PartOfferAggregation[] => {
	const aggregations = useMemo(() => {
		const aggregationMap = jobParts.reduce((acc, jobPart) => {
			acc.set(jobPart.transientId, {
				jobPart,
				offers: []
			});

			return acc;
		}, new Map<TransientPartId, PartOfferAggregation>());

		for (const match of matches) {
			const aggregation = aggregationMap.get(match.job_part_id);
			if (!aggregation) {
				// This shouldn't happen, but just in case
				continue;
			}

			const offer = findOfferById(offers, match.offer_id);

			if (!offer) {
				// This shouldn't happen until we add support for group
				// supersessions which require additional UI logic, so we should
				// filter these out for now.
				continue;
			}
			aggregation.offers.push(offer);
		}
		return Array.from(aggregationMap.values()).sort((a, b) => {
			const aName = createJobPartName(a.jobPart);
			const bName = createJobPartName(b.jobPart);
			const alphaCompare = aName.localeCompare(bName);

			// If they are the same alphabetically, compare by length
			if (alphaCompare === 0) {
				return aName.length - bName.length; // Shorter names come first
			}

			return alphaCompare;
		});
	}, [jobParts, matches, offers]);

	return aggregations;
};
