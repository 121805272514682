import { lookup } from '@partly/core-server-client';
import { ListingGrade } from '@sdk/lib';
import { sortBy } from 'lodash-es';

export const formatGradeRange = (grades: ListingGrade[]) => {
	const listingGradeSorting: Record<ListingGrade, number> = {
		'0': 0,
		A: 1,
		B: 2,
		C: 3,
		D: 4
	};

	if (grades.length === 0) {
		return '--';
	}
	if (grades.length === 1 && grades[0] === '0') {
		return `New`;
	}

	if (grades.length === 1) {
		return `Grade ${grades[0]}`;
	}

	const sortedGrades = sortBy([...grades], grade => listingGradeSorting[grade]);

	if (sortedGrades[0] === '0') {
		return `New and Grade ${
			sortedGrades.length <= 2 ? sortedGrades[1] : `${sortedGrades.at(1)} - ${sortedGrades.at(-1)}`
		}`;
	}

	return `Grade ${sortedGrades.at(0)} - ${sortedGrades.at(-1)}`;
};

export const formatGrade = (grade: ListingGrade) => {
	if (grade === '0') {
		return 'New';
	}

	return `Grade ${grade}`;
};

export const localiseCondition = (condition: lookup.SellableCondition) => {
	if (condition === 'new') {
		return 'OEM New';
	}
	if (condition.used && 'panel' in condition.used) {
		switch (condition.used.panel) {
			case 'grade0':
				return 'NEW';
			case 'grade_a':
				return 'Used A';
			case 'grade_b':
				return 'Used B';
			case 'grade_c':
				return 'Used C';
			default:
				return '--';
		}
	}
};
