import { coreSdk } from '@/sdk/reflect';
import { getConfig, updateConfig } from '@sdk/react';
import { JWTPayload, decodeJwt } from 'jose';
import { isNil, isString } from 'lodash-es';
import { EventBus, createEventDefinition } from 'ts-bus';

const ACCESS_TOKEN = 'access-token';
const REFRESH_TOKEN = 'refresh-token';

export type AuthTokens = {
	accessToken: string | null;
	refreshToken: string | null;
};

export type AuthState =
	| {
			isAuthenticated: true;
			userId: string;
			repairerId: string;
	  }
	| { isAuthenticated: false };

export const authBus = new EventBus();
export const onAuthChange = createEventDefinition<AuthState>()('auth.signIn');

export const resolveAuth = async (): Promise<AuthState> => {
	const tokens = getAuthTokens();

	if (!tokens.accessToken) {
		return {
			isAuthenticated: false
		};
	}

	const jwt = decodeJwt(tokens.accessToken);

	const userId = getUserId(jwt);
	const repairerId = getRepairerId(jwt);
	setSdkToken(tokens.accessToken, repairerId);

	if (isTokenCloseToExpiring(jwt) && tokens.refreshToken) {
		const refreshed = await refresh(tokens.refreshToken);
		setSdkToken(refreshed?.accessToken ?? tokens.accessToken, repairerId);
	}

	if (isTokenValid(jwt) && !isNil(userId) && !isNil(repairerId)) {
		return {
			isAuthenticated: true,
			userId,
			repairerId
		};
	}

	return {
		isAuthenticated: false
	};
};

const isTokenValid = (jwt: JWTPayload): boolean => {
	if (!jwt.exp) {
		return false;
	}

	const nowInSeconds = new Date().getTime() / 1000;
	return jwt.exp >= nowInSeconds;
};

const isTokenCloseToExpiring = (jwt: JWTPayload): boolean => {
	if (!jwt.exp) {
		return false;
	}

	const nowInSeconds = new Date().getTime() / 1000;
	return Math.abs(jwt.exp - nowInSeconds) < 5 * 60;
};

const getRepairerId = (jwt: JWTPayload): string | undefined => {
	if (!('authorization_details' in jwt && Array.isArray(jwt.authorization_details))) {
		return undefined;
	}

	const authorization_details = jwt.authorization_details as { issuer: string }[];
	const detail = authorization_details.find(
		({ issuer }) => issuer === '/api/v1/repairers.verify'
	) as { issuer: '/api/v1/repairers.verify'; parameters: { repairer_id: string } } | undefined;
	if (!detail) {
		return undefined;
	}

	return detail.parameters.repairer_id;
};

const getUserId = (jwt: JWTPayload): string | undefined => {
	if ('sub' in jwt && isString(jwt.sub)) {
		return jwt.sub;
	}

	return undefined;
};

export const signIn = (accessToken: string, refreshToken: string | null): string | undefined => {
	const jwt = decodeJwt(accessToken);
	const userId = getUserId(jwt);
	const repairerId = getRepairerId(jwt);
	localStorage.setItem(ACCESS_TOKEN, accessToken);
	if (refreshToken) {
		localStorage.setItem(REFRESH_TOKEN, refreshToken);
	}
	setSdkToken(accessToken, repairerId);
	if (userId && repairerId) {
		authBus.publish(onAuthChange({ isAuthenticated: true, userId, repairerId }));
	}
	return userId;
};

export const signOut = () => {
	localStorage.removeItem(ACCESS_TOKEN);
	localStorage.removeItem(REFRESH_TOKEN);
	setSdkToken(undefined, undefined);
	authBus.publish(onAuthChange({ isAuthenticated: false }));
};

const getAuthTokens = (): AuthTokens => {
	const accessToken = localStorage.getItem(ACCESS_TOKEN);
	const refreshToken = localStorage.getItem(REFRESH_TOKEN);
	return {
		accessToken,
		refreshToken
	};
};

const setSdkToken = (token: string | undefined, repairerId: string | undefined) => {
	updateConfig({
		userToken: token ? { token } : undefined,
		organizationId: repairerId ?? undefined
	});
};

const refresh = async (refreshToken: string) => {
	const [api] = coreSdk(getConfig());
	const res = await api.users.signin({ refresh_token: refreshToken }, {});
	if (res.is_ok()) {
		const { access_token, refresh_token } = res.unwrap_ok();
		return { accessToken: access_token, refreshToken: refresh_token ?? refreshToken };
	}
	return null;
};

export const refreshOrSignOut = async () => {
	const { refreshToken } = getAuthTokens();
	if (refreshToken) {
		const res = await refresh(refreshToken);
		if (!isNil(res)) {
			signIn(res.accessToken, res.refreshToken);
			return true;
		}
	}
	signOut();
	return false;
};

(window as any).setSdkToken = (token: string) => updateConfig({ userToken: { token } });
