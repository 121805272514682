import { format } from 'date-fns';

/**
 * Converts a month number to its three-letter abbreviation.
 */
const getShortMonth = (monthNumber: number): string => {
	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];

	return months[monthNumber - 1];
};

type YearRangeProperties = {
	year?: string;
	yearFrom?: string;
	yearTo?: string;
	monthFrom?: string;
	monthTo?: string;
};

/**
 * Create a year range string based on the year information provided.
 *
 * @example 2012, Mar 2012, Mar - Dec 2012, Mar 2012 - Feb 2016
 */
export const getYearRange = (properties: YearRangeProperties) => {
	const monthFromNum = Number(properties.monthFrom);
	const monthToNum = Number(properties.monthTo);

	const monthFrom = isNaN(monthFromNum) ? null : getShortMonth(monthFromNum);
	const monthTo = isNaN(monthToNum) ? null : getShortMonth(monthToNum);

	if (properties.year) {
		return properties.year;
	}

	if (!properties.yearFrom) {
		return '';
	}

	if (properties.yearFrom === properties.yearTo) {
		if (!monthFrom && !monthTo) {
			return properties.yearFrom;
		}

		if (monthFrom === monthTo) {
			return `${monthFrom} ${properties.yearFrom}`;
		}

		return `${monthFrom} - ${monthTo} ${properties.yearFrom}`;
	}

	if (!properties.yearTo) {
		return properties.yearFrom;
	}

	if (!monthFrom && !monthTo) {
		return `${properties.yearFrom} - ${properties.yearTo}`;
	}

	if (!monthFrom) {
		return `${properties.yearFrom} - ${monthTo} ${properties.yearTo}`;
	}

	if (!monthTo) {
		return `${monthFrom} ${properties.yearFrom} - ${properties.yearTo}`;
	}

	return `${monthFrom} ${properties.yearFrom} - ${monthTo} ${properties.yearTo}`;
};

export const secondsToHumanTime = (seconds: number): string => {
	const midnight = new Date().setHours(0, 0, 0, 0);
	const time = new Date(midnight + seconds * 1000);

	return time.getHours() === 12 ? 'noon' : format(time, 'ha').toLowerCase();
};

export const daysToHumanString = (days: number): string => {
	const dayAliases = ['Same day', 'Next day'];

	return dayAliases[days] ?? `${days} days`;
};
