import { Badge } from '@/app/atoms/badge';
import { formatSubtotal } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { OfferSelection, PartOfferAggregation } from '../../models';
import { createJobPartName, getOffersSummary } from '../../utils';
import { useMemo } from 'react';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { ConditionBadge } from '../condition-badge';
import { ChevronRightIcon, CubeIcon } from '@heroicons/react/24/outline';
import { RandomColorBadge } from '../random-color-badge';

interface JobPartCardProps {
	deliverBefore: Date;
	partOfferAggregation: PartOfferAggregation;
	offerSelection: OfferSelection;
	parentAssemblyId: string | null;
	isSelected: boolean;
	onSelect(): void;
}

export const JobPartCard = ({
	partOfferAggregation,
	deliverBefore,
	offerSelection,
	parentAssemblyId,
	isSelected,
	onSelect
}: JobPartCardProps) => {
	const context = createJobPartName(partOfferAggregation.jobPart);
	const hca = partOfferAggregation.jobPart.assembly?.hcas.map(hca => hca).join(' > ') ?? '';

	const summary = useMemo(
		() => getOffersSummary(offerSelection, partOfferAggregation),
		[offerSelection, partOfferAggregation]
	);

	const availableOptionsDiffCount =
		(summary.selected?.totalCount || 0) - partOfferAggregation.offers.length;

	return (
		<button
			onClick={onSelect}
			className={tlsx(
				'flex flex-col min-h-[140px] items-start p-4 rounded-md outline-theme-500 w-full',
				{
					'bg-gray-100': isSelected,
					'hover:bg-gray-50': !isSelected
				}
			)}
		>
			<div className="flex w-full text-left text-sm font-semibold justify-between gap-3">
				<div className="break-words max-w-[200px] ">
					<span>
						{partOfferAggregation.jobPart.quantity > 1
							? `${partOfferAggregation.jobPart.quantity} x `
							: null}
						{context}
					</span>
				</div>

				<div className="text-right">
					<span className="font-normal">
						{summary.potential &&
						partOfferAggregation.offers.length > 0 &&
						summary.potential.minPrice
							? 'From '
							: ''}
					</span>
					<span className="whitespace-nowrap">
						{formatSubtotal({
							currency: 'NZD',
							price: summary.selected
								? summary.selected.totalPrice
								: summary.potential?.minPrice || 0,
							displayPrice: null
						})}
					</span>
				</div>
			</div>
			{hca && (
				<p className="text-xs text-left text-gray-700 mt-1.5">
					{partOfferAggregation.jobPart.assembly?.hcas.map(hca => hca).join(' > ')}
				</p>
			)}
			{summary.selected ? (
				<FormattedShippingTime
					className="mt-2 mb-1 text-xs text-gray-700"
					shippingTime={summary.selected.shippingTime || null}
					deliverBefore={deliverBefore}
				/>
			) : null}
			{summary.selected ? (
				<ul className="w-full mt-2 flex flex-col gap-0.5 text-gray-700 text-xs">
					{summary.selected.suppliers.map(supplier => (
						<li key={supplier.id} className="flex items-center justify-between">
							<div className="flex items-center gap-1">
								<CubeIcon width={16} className="text-inherit" />
								<span className="">{supplier.name}</span>
							</div>
							<div>
								{summary.selected!.conditionsPerSupplier[supplier.id]?.map(
									({ condition, isAssembly }, i, self) => (
										<div key={i} className="flex">
											{condition ? <ConditionBadge condition={condition} /> : null}
											{isAssembly && (
												<Badge className="ml-2" variant="blue" size="small" rounded>
													Assembly
												</Badge>
											)}
											{i !== self.length - 1 ? ',' : null}
										</div>
									)
								)}
							</div>
						</li>
					))}
				</ul>
			) : null}

			<div className="flex w-full text-xs mt-auto justify-between items-center">
				{summary.potential ? (
					<Badge className="mt-3" variant="red" size="small">
						Choose supplier
					</Badge>
				) : (
					summary.selected &&
					summary.selected.suppliers.length > 1 && (
						<Badge className="mt-3" variant="yellow" size="small" rounded>
							Multiple suppliers
						</Badge>
					)
				)}

				{summary.potential || availableOptionsDiffCount > 0 ? (
					<div className="text-blue-600 flex gap-2 mt-3 ml-auto -mr-0.5">
						<span className="whitespace-nowrap">
							{summary.potential
								? summary.potential?.supplyOptionsCount === 1
									? '1 supply option'
									: `${summary.potential?.supplyOptionsCount} supply options`
								: availableOptionsDiffCount == 1
									? '1 more option'
									: `${availableOptionsDiffCount} more options`}
						</span>
						<ChevronRightIcon width={12} />
					</div>
				) : null}
				{parentAssemblyId ? (
					<div className="mt-2">
						<RandomColorBadge idToHash={parentAssemblyId}>Assembly</RandomColorBadge>
					</div>
				) : null}
			</div>
		</button>
	);
};
