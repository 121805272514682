import CreateJobPage from '@/app/pages/create-job';
import JobDetailPage from '@/app/pages/job-detail';
import { RootTemplate } from '@/app/templates/root-template';
import { Skeleton } from '@mantine/core';
import CollisionMapPage from '@pages/collision-map';
import JobPage from '@pages/job';
import JobsPage from '@pages/jobs';
import OrdersPage from '@pages/order-confirmation';
import SignInPage from '@pages/sign-in';
import VehicleResultsPage from '@pages/vehicle-results';
import VehicleSearchPage from '@pages/vehicle-search';
import { Navigate, RouteObject } from 'react-router-dom';
import ErrorStateBoundary from './atoms/error-state-boundary';
import { SplitCollisionSkeleton } from './features/collisions/components/split-collision-skeleton';
import { WithSuspense } from './hoc/with-suspense';
import PartInterpretationLoaderPage from './pages/part-interpretation-loader';
import PartsPage from './pages/parts';
import { SupplyPage } from './pages/supply';

export const routes: RouteObject[] = [
	{
		path: '/sign-in',
		element: <SignInPage />
	},
	{
		path: '/',
		element: <RootTemplate />,
		children: [
			{
				index: true,
				element: <Navigate to="/jobs" replace />
			},
			{
				path: '/jobs',
				element: <JobsPage />
			},
			{
				path: '/jobs/create',
				element: <VehicleSearchPage />,
				children: [
					{
						index: true,
						element: <CreateJobPage />
					},
					{
						path: 'vehicles',
						element: <VehicleResultsPage />
					},
					{
						path: '*',
						element: <Navigate to="/jobs/create" />
					}
				]
			},
			{
				path: '/',
				element: <JobDetailPage />,
				children: [
					{
						path: '/job/:jobId',
						element: <JobPage />
					},
					{
						path: '/job/:jobId/collision',
						element: <WithSuspense Component={CollisionMapPage} Fallback={SplitCollisionSkeleton} />
					},
					{
						path: '/job/:jobId/parts',
						element: <PartInterpretationLoaderPage />,
						children: [
							{
								path: '/job/:jobId/parts',
								element: <WithSuspense Component={PartsPage} Fallback={Skeleton} />
							}
						]
					},
					{
						path: '/job/:jobId/supply',
						element: <SupplyPage />
					},
					{
						path: '/job/:jobId/orders',
						element: <OrdersPage />
					}
				]
			}
		],
		ErrorBoundary: ErrorStateBoundary
	},
	{
		path: '*',
		element: <Navigate to="/" />
	}
];
