import { EmptyState } from '@/app/atoms/empty-state';
import ImageWithSkeleton from '@/app/atoms/image-with-skeleton';
import { Section } from '@/app/templates/page-section';
import { tlsx } from '@/app/utils/tw-merge';
import { jobsQueries } from '@/sdk/react';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { InheritableElementProps } from '@/types/utilties';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

export const JobImageGrid = ({
	jobId,
	className
}: InheritableElementProps<'section', { jobId: string }>) => {
	const client = useQueryClient();

	const { data: jobData } = useSuspenseQuery({
		...jobsQueries.get({ jobId }),
		retryOnMount: false
	});

	const { mutateAsync: syncJobImages, isPending } = useMutation({
		...bmsMutations.syncJobImages,
		onSuccess: () => {
			client.invalidateQueries(jobsQueries.get({ jobId }));
		}
	});

	return (
		<Section className={tlsx('bg-white p-6 border rounded-md', className)}>
			<div className="flex items-center justify-between">
				<Section.Title className="text-lg">Images</Section.Title>
				{jobData.job.bmsIntegrated && (
					<button
						type="button"
						className="p-1.5 rounded hover:bg-gray-100 active:bg-gray-100"
						onClick={() => syncJobImages({ jobId })}
					>
						<ArrowPathIcon className="size-4" />
					</button>
				)}
			</div>
			{jobData.job.images.length > 0 ? (
				<div className="grid grid-cols-3 gap-2 mt-4">
					{jobData.job.images.map(image => (
						<a
							key={image.original}
							className="col-span-1 aspect-square bg-gray-50 rounded-md"
							href={image.original}
							target="_blank"
							rel="noopener noreferrer"
						>
							<ImageWithSkeleton
								className="object-cover w-full aspect-square rounded"
								src={image.original}
								loading="lazy"
							/>
						</a>
					))}
				</div>
			) : (
				<EmptyState className="bg-white py-4">
					<EmptyState.Title>No images</EmptyState.Title>
					<EmptyState.Description>
						{jobData.job.bmsIntegrated
							? 'Sync to see images from iBodyShop'
							: 'No images available for this job'}
					</EmptyState.Description>
					{jobData.job.bmsIntegrated && (
						<Button
							variant="subtle"
							color="blue"
							onClick={() => syncJobImages({ jobId })}
							loading={isPending}
						>
							Sync images
						</Button>
					)}
				</EmptyState>
			)}
		</Section>
	);
};
