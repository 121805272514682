import { EmptyState } from '@/app/atoms/empty-state';
import { EnrichedJobPart, OfferSelection, PartOfferAggregation } from '../models';
import { OfferColumn } from './offer-column';
import { getOffersPerCondition } from '../utils';
import { useMemo } from 'react';
import { Grid } from '@mantine/core';
import { offers_search } from '@partly/core-server-client';

interface SupplyDetailsProps {
	partOfferAggregation: PartOfferAggregation | null;
	offerSelection: OfferSelection;
	deliverBefore: Date;
	onSelectionChange: (jobPart: EnrichedJobPart, offer: offers_search.Offer) => void;
}

export const SupplyDetails = ({
	partOfferAggregation,
	offerSelection,
	deliverBefore,
	onSelectionChange
}: SupplyDetailsProps) => {
	const offers = useMemo(
		() => ({
			new: getOffersPerCondition(partOfferAggregation?.offers || [], 'new'),
			used: getOffersPerCondition(partOfferAggregation?.offers || [], 'used')
		}),
		[partOfferAggregation]
	);

	if (!partOfferAggregation) {
		return (
			<EmptyState className="bg-transparent">
				<EmptyState.Title>No part selected</EmptyState.Title>
				<EmptyState.Description>There are no parts selected for this job</EmptyState.Description>
			</EmptyState>
		);
	}

	if (partOfferAggregation.offers.length === 0) {
		return (
			<EmptyState className="bg-transparent">
				<EmptyState.Title>No offers available</EmptyState.Title>
				<EmptyState.Description>There are no offers available for this part</EmptyState.Description>
			</EmptyState>
		);
	}

	return (
		<Grid gutter={48} className="p-6 w-full">
			<Grid.Col xl={offers.new.length > 1 ? 6 : 'content'}>
				<OfferColumn
					condition="new"
					deliverBefore={deliverBefore}
					offerSelection={offerSelection}
					offers={offers.new}
					onSelectionChange={offer => onSelectionChange(partOfferAggregation.jobPart, offer)}
				/>
			</Grid.Col>
			<Grid.Col xl={offers.used.length > 1 ? (offers.new.length < 1 ? 'auto' : 6) : 'content'}>
				<OfferColumn
					condition="used"
					deliverBefore={deliverBefore}
					offerSelection={offerSelection}
					offers={offers.used}
					onSelectionChange={offer => onSelectionChange(partOfferAggregation.jobPart, offer)}
				/>
			</Grid.Col>
		</Grid>
	);
};
