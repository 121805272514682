import { createMoney } from '@/app/utils/currency';
import { formatSelectionContextReflect } from '@/app/utils/part';
import { JobPart, SupplyVendor } from '@/sdk/lib';
import { draft_order, listing, part_slot } from '@/sdk/reflect/reflect';
import { encodeGapcPartIdentityKey } from '@/sdk/reflect/resource';
import { match } from '@/types/match';
import { isDefined } from '@partly/js-ex';
import { lookup, offers_search } from '@partly/core-server-client';
import { isBefore, roundToNearestHours } from 'date-fns';
import { UseFormReturn } from 'react-hook-form';
import { v4 } from 'uuid';
import {
	AddItemPayload,
	DraftOrderItemModel,
	DraftOrderSelection,
	EnrichedJobPart,
	OrderRequestModel
} from './models';
import { createPartSelectionContexts, getBusiness, getOfferSellable } from './utils';

export const restoreOrderRequestModel = (
	request: draft_order.exp.DraftOrder
): OrderRequestModel => {
	const items: DraftOrderItemModel[] = request.items.map(item => {
		return {
			...item,
			id: item.id,
			local_id: item.id,
			name:
				item.buyable.type === 'Listing'
					? item.buyable.offer.type === 'Kit'
						? item.buyable.offer.listing.name
						: formatSelectionContextReflect(item.context)
					: item.buyable.type === 'External'
						? item.buyable.description
						: '',
			buyable: createBuyableOffer(item.buyable)
		};
	});

	return {
		...request,
		local_id: sanitiseOrderId(request.id),
		// We have re-purposed this field as core server now does the automation.
		instant_accept: request.attempt_auto_transition_order.enabled,
		order_id: request.id,
		items
	};
};

export const sanitiseOrderId = (id: string): string => {
	// We use the id as a key in the form state. We don't want
	// user input to accidently mess with paths so we santise any
	// orders stored to not have full stops (which can mess with paths).
	return id.replaceAll('.', '');
};

type SellableDetails = {
	arrivalTime: number | null | undefined;
	price: string | null;
	condition: lookup.SellableCondition | null | undefined;
	sellableId: string;
};

export const createOrderRequestItem = (
	offer: offers_search.Offer,
	context: part_slot.exp.PartSelectionContexts | null,
	quantity: number
): DraftOrderItemModel | undefined => {
	// NOTE: Victor: Defaulting to eta, since arrival at seems to be not used, and returns a linux timestamp from 1970...
	const etaToTimestamp = (eta: string | null | undefined): number | undefined => {
		if (!eta) {
			return undefined;
		}
		return new Date(eta).getTime();
	};

	const sellable = getOfferSellable(offer);

	if (offer.type === 'group' || !sellable) {
		// TODO: handle groups
		return;
	}

	const sellableDetails: SellableDetails = {
		arrivalTime: etaToTimestamp(sellable.shipping_time?.eta),
		price: sellable.price,
		condition: sellable.entity.condition,
		sellableId: sellable.entity.id
	};

	const priceAsNumber = Number(sellable.price);
	if (Number.isNaN(priceAsNumber)) {
		throw new Error(`Invalid price: ${sellable.price}`);
	}

	return {
		id: null,
		local_id: v4(),
		status: 'Pending',
		order_separately: false,
		arrival_at: sellableDetails.arrivalTime
			? new Date(sellableDetails.arrivalTime).valueOf()
			: null,
		context,
		grade: mapConditionToGrade(sellableDetails.condition),
		quantity,
		price: createMoney(priceAsNumber),
		buyable: {
			type: 'Sellable',
			sellable_id: sellableDetails.sellableId
		}
	};
};

// Todo: we need orders to move to handle the new grade type
const mapConditionToGrade = (
	condition: lookup.SellableCondition | null | undefined
): listing.ListingGrade => {
	if (!condition) {
		return '0';
	}

	return match(condition, {
		new: () => '0',
		used: arg =>
			match(arg, {
				panel: panel =>
					match(panel, {
						grade0: () => '0',
						grade_a: () => 'A',
						grade_b: () => 'B',
						grade_c: () => 'C'
					}),
				// Un-used
				distance_traveled: () => '0',
				operation_hours: () => '0',
				remaining_thickness: () => '0'
			})
	});
};

const createBuyableOffer = (
	buyable: draft_order.exp.DraftOrderItemBuyable
): draft_order.DraftOrderItemBuyable => {
	if (buyable.type === 'Listing') {
		if (buyable.offer.type === 'Product') {
			return {
				type: 'Listing',
				offer: {
					type: 'Product',
					offer_id: buyable.offer.offer_id,
					listing_id: buyable.offer.listing.id
				}
			};
		}

		return {
			type: 'Listing',
			offer: {
				type: 'Kit',
				listing_id: buyable.offer.listing.id,
				offer_ids: buyable.offer.offer_ids
			}
		};
	}

	if (buyable.type === 'External') {
		return {
			type: 'External',
			description: buyable.description,
			identity: buyable.identity
		};
	}

	return {
		type: 'Sellable',
		sellable_id: buyable.sellable_id
	};
};

const isEditable = (order: OrderRequestModel): boolean => {
	return match(order.status, {
		Draft: () => true,
		Processing: () => false,
		Processed: () => true,
		Cancelled: () => false,
		Finalised: () => false
	});
};

export const getMinDeliveryDate = (orders: OrderRequestModel[]): Date => {
	const relevantOrders = orders.filter(isEditable);
	const itemDates: Date[] = [];
	for (const order of relevantOrders) {
		for (const item of order.items) {
			// check if arrial date is set and if its a back order of item has been rejected
			if (
				!item.arrival_at ||
				item.order_separately ||
				(typeof item.status === 'object' && 'Rejected' in item.status)
			) {
				continue;
			}

			const arrivalAt = new Date(item.arrival_at);
			itemDates.push(arrivalAt);
		}
	}

	const latestDate = getLatestDate(itemDates);
	const now = new Date();

	if (!latestDate || isBefore(latestDate, now)) {
		return now;
	}

	return latestDate;
};

export const getDeliveryDateFromOrders = (
	orders: OrderRequestModel[],
	defaultDeliveryDate: Date
) => {
	// We only want to consider draft orders where decisions can still be made
	const relevantOrders = orders.filter(
		order =>
			match(order.status, {
				Cancelled: () => false,
				Finalised: () => false,
				Draft: () => true,
				Processing: () => true,
				Processed: () => true
			}),
		() => false
	);

	const orderDates: Date[] = [];
	for (const order of relevantOrders) {
		// It is possible that an order has come back processed, but
		// with all the items rejected. In this case we want to exclude the
		// order + items from the delivery date calculation.
		let hasOrderableItems = false;
		for (const item of order.items) {
			if (typeof item.status === 'object' && 'Rejected' in item.status) {
				continue;
			}

			// Some items won't have an arrival date, this is perfectly valid
			if (!item.arrival_at) {
				continue;
			}

			// Some items are ordered separately and don't
			// affect the delivery date so we can skip these.
			if (item.order_separately) {
				continue;
			}

			// At least one item is orderable
			hasOrderableItems = true;
			const arrivalAt = new Date(item.arrival_at);
			orderDates.push(arrivalAt);
		}

		if (hasOrderableItems) {
			if (order.status === 'Draft') {
				// If the order is still in draft, then the delivery date
				// is derrived from the global selected delivery date.
				orderDates.push(defaultDeliveryDate);
				continue;
			}

			// Otherwise use the saved delivery date
			const targetDeliveryDate = new Date(order.target_deliver_before_timestamp);
			orderDates.push(targetDeliveryDate);
		}
	}

	if (orderDates.length === 0) {
		// If there is no orders, then we can just default to
		// the nearest hour.
		return getNearestHour();
	}

	return getLatestDate(orderDates);
};

export const getNearestHour = (): Date => {
	const date = new Date();
	return roundToNearestHours(date, { roundingMethod: 'ceil' });
};

export const getDeliveryDateFromSelection = (selection: DraftOrderSelection): Date => {
	const models = Object.values(selection.draft_orders);
	const earliestDeliveryDatae = getDeliveryDateFromOrders(models, selection.delivery_date);

	// It is possible that the user has manually selected a
	// date that is later than the earliest possible. We move
	// the date forward automatically, but never backwards
	// automatically.
	if (earliestDeliveryDatae < selection.delivery_date) {
		return selection.delivery_date;
	}

	return earliestDeliveryDatae;
};

export const createItemContext = (
	part: JobPart | null | undefined
): part_slot.exp.PartSelectionContexts | null => {
	if (!part) {
		return null;
	}

	const context: part_slot.exp.PartSelectionContext = {
		description: part.description,
		mpn: part.mpn,
		gapc_brand: null,
		gapc_part_type: null,
		gapc_position: null,
		hcas: part.assembly?.hcas ?? null
	};

	if (part.partSlot?.gapcPartType) {
		context.gapc_part_type = {
			id: part.partSlot.gapcPartType.id,
			name: part.partSlot.gapcPartType.name,
			aliases: [],
			categorizations: [],
			gapc_properties: [],
			uvdb_property_prefixes: []
		};
	}

	if (part.partSlot?.gapcPosition) {
		context.gapc_position = {
			id: part.partSlot.gapcPosition.id,
			name: part.partSlot.gapcPosition.name
		};
	}

	if (part.gapcBrand) {
		context.gapc_brand = {
			id: part.gapcBrand.id,
			name: part.gapcBrand.name,
			is_oem: part.gapcBrand.isOem
		};
	}

	return [context];
};

export const contextToGapcPartIdentities = (
	context: part_slot.exp.PartSelectionContexts | null
): string[] | null => {
	if (!context) {
		return null;
	}

	return context
		.map(jobItemContext => {
			const { mpn } = jobItemContext;
			const gapc_brand_id = jobItemContext?.gapc_brand?.id;
			if (!mpn || !gapc_brand_id) {
				return null;
			}
			return encodeGapcPartIdentityKey({
				mpn: mpn!,
				gapc_brand_id: gapc_brand_id!
			});
		})
		.filter(isDefined);
};

export const createNewOrderRequest = (
	vendor: SupplyVendor,
	items: DraftOrderItemModel[]
): OrderRequestModel => {
	return {
		status: 'Draft',
		created_at: new Date().valueOf(),
		instant_accept: false,
		updated_at: null,
		local_id: v4(),
		vendor: {
			Partner: vendor
		},
		target_deliver_before_timestamp: new Date().valueOf(),
		vendor_notes: null,
		items,
		estimator_notes: null,
		images: [],
		order_id: null
	};
};

export const applyOfferSelection = (
	jobPart: EnrichedJobPart,
	offer: offers_search.Offer,
	form: Pick<UseFormReturn<DraftOrderSelection>, 'setValue' | 'getValues'>
) => {
	let isSelected = true;

	const selection = form.getValues();
	const updateDeliveryDate = () => {
		const latestState = form.getValues();
		const deliveryDate = getDeliveryDateFromSelection(latestState);
		form.setValue('delivery_date', deliveryDate);
	};

	const sellable = getOfferSellable(offer);
	const business = getBusiness(offer);

	if (offer.type === 'group' || !sellable || !business) {
		// TODO: handle groups
		return;
	}

	// Step 1: Check if we already have a draft order for this vendor
	// that we can re-use.
	const existingRequest = Object.values(selection.draft_orders).find(
		// Business is the vendor
		order => order.vendor.Partner.id === business.id && order.status === 'Draft'
	);

	// Step 2: Create the item for the order based on context + offer
	const context = createItemContext(jobPart);
	const newItem = createOrderRequestItem(offer, context, jobPart.quantity);

	if (!newItem) {
		return;
	}

	// Step 3: If we don't have an existing request, create a new one
	// with a single item.
	if (!existingRequest) {
		const newOrder = createNewOrderRequest(business, [newItem]);
		form.setValue(`draft_orders.${newOrder.local_id}`, newOrder);
		updateDeliveryDate();
		return;
	}

	// Step 4: If we do have an existing request, then we need to update.
	// Start by finding the existing item, supply will always be a Sellabe
	// so we can compare by the offer id.
	const existingItemIndex = existingRequest.items.findIndex(
		item => item.buyable.type === 'Sellable' && offer.id === item.buyable.sellable_id
	);

	// Step 5: If it is a new item then add it and return early.
	if (existingItemIndex === -1) {
		existingRequest.items.push(newItem);
		form.setValue(`draft_orders.${existingRequest.local_id}`, existingRequest);
		updateDeliveryDate();
		return;
	}

	// Step 6: If it already exists then we are toggling to remove it.
	// We need to make sure that the order can still exist without the item.
	existingRequest.items.splice(existingItemIndex, 1);
	if (existingRequest.items.length === 0) {
		delete selection.draft_orders[existingRequest.local_id];
		isSelected = false;
	}

	form.setValue(`draft_orders`, selection.draft_orders);
	updateDeliveryDate();
	return isSelected;
};

export const getLatestDate = (dates: Date[]): Date => {
	return dates.reduce((acc, date) => {
		if (date > acc) {
			return date;
		}

		return acc;
	}, dates[0]);
};

export const restoreOrderSelection = (
	selection: DraftOrderSelection,
	draft_orders: draft_order.exp.DraftOrder[]
): DraftOrderSelection => {
	for (const draftOrder of draft_orders) {
		const model = restoreOrderRequestModel(draftOrder);
		selection.draft_orders[model.local_id] = restoreOrderRequestModel(draftOrder);
	}

	const models = Object.values(selection.draft_orders);
	const earliestDeliveryDate = getDeliveryDateFromOrders(models, selection.delivery_date);

	// We restore whatever the user has selected at
	// the delivery date. We don't want the check for existing
	// selection hence we don't use getDeliveryDateFromSelection.
	selection.delivery_date = earliestDeliveryDate;

	return selection;
};

export const getLatestPossibleDeliveryDateFromSelection = (
	selection: DraftOrderSelection
): Date => {
	const dates = Object.values(selection.draft_orders)
		.filter(order => order.status !== 'Finalised')
		.flatMap(order => {
			const itemDates = order.items
				.filter(item => !item.order_separately)
				.map(item => (item.arrival_at ? new Date(item.arrival_at) : new Date()));

			return itemDates;
		});

	return getLatestDate(dates);
};

export const onAddExternalItem = (
	selection: DraftOrderSelection,
	data: AddItemPayload,
	form: UseFormReturn<DraftOrderSelection>
) => {
	const existingOrder = Object.values(selection.draft_orders).find(
		order => order.vendor.Partner.id == data.vendor.id && order.status === 'Draft'
	);
	if (!existingOrder) {
		const newOrder = createNewOrderRequest(data.vendor, [data.item]);
		form.setValue(`draft_orders.${newOrder.local_id}`, newOrder);
		return;
	}

	existingOrder.items.push(data.item);
	form.setValue(`draft_orders.${existingOrder.local_id}`, existingOrder);
};

type RemoteOrderIds = Map<string, Map<string, string>>;

// todo: this method could do with a refactor
export const buildIngestFromSelection = (
	selection: DraftOrderSelection,
	remoteRequests: draft_order.exp.DraftOrder[],
	supplyHashId: string,
	instantAccept: boolean
): draft_order.DraftOrderIngest[] => {
	// If we have any draft orders, only send those to the server.
	// Otherwise, send everything.
	let values = Object.values(selection.draft_orders);
	const hasDrafts = values.some(order => order.status === 'Draft');
	values = values.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	remoteRequests = remoteRequests.filter(order => {
		if (hasDrafts) {
			return order.status === 'Draft';
		}

		return true;
	});

	const remoteIds = remoteRequests.reduce((acc, request) => {
		if (request.status !== 'Draft') {
			return acc;
		}

		acc.set(
			request.id,
			request.items.reduce((acc, item) => {
				acc.set(item.id, item.id);
				return acc;
			}, new Map<string, string>())
		);

		return acc;
	}, new Map() as RemoteOrderIds);

	const ingests: draft_order.DraftOrderIngest[] = [];

	for (const orderRequest of values) {
		// Draft orders are either updates or inserts
		match(orderRequest.status, {
			Draft: () => {
				if (orderRequest.order_id) {
					const items: draft_order.DraftOrderItemIngest[] = orderRequest.items.map(item => {
						const commonItem = {
							// NOTE: VICTOR - This changes the arrival_at to the date from target_deliver_before_timestamp,
							// can default to that since it will always exist and is the final date for delivery?
							arrival_at: orderRequest.target_deliver_before_timestamp,
							context: createPartSelectionContexts(item.context),
							grade: item.grade,
							price: item.price,
							quantity: item.quantity
						};

						if (item.id) {
							remoteIds.get(orderRequest.local_id)?.delete(item.id);
							return <draft_order.DraftOrderItemIngest>{
								Update: { id: item.id, ...commonItem }
							};
						}
						return <draft_order.DraftOrderItemIngest>{
							Insert: {
								...commonItem,
								buyable: item.buyable
							}
						};
					});

					const itemsToDelete = Array.from(remoteIds.get(orderRequest.order_id)?.keys() ?? []);
					for (const toDelete of itemsToDelete) {
						items.push({ Remove: toDelete });
					}

					remoteIds.delete(orderRequest.order_id);

					ingests.push({
						Update: {
							id: orderRequest.order_id,
							attempt_auto_transition_order: instantAccept,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							images: orderRequest.images.map(image => image.original),
							items
						}
					});
				} else {
					// If the order is new, we don't need to check any of the ids
					ingests.push({
						Insert: {
							attempt_auto_transition_order: instantAccept,
							estimator_notes: orderRequest.estimator_notes,
							supply_hash_id: supplyHashId,
							images: orderRequest.images.map(image => image.original),
							vendor: {
								Partner: orderRequest.vendor.Partner.id
							},
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items.map(item => {
								const insert: draft_order.DraftOrderItemInsert = {
									buyable: item.buyable,
									arrival_at: orderRequest.target_deliver_before_timestamp,
									context: createPartSelectionContexts(item.context),
									grade: item.grade,
									price: item.price,
									quantity: item.quantity
								};

								return insert;
							})
						}
					});
				}
			},
			Cancelled: () => {
				/** no-op. Cancels are done immedietly  */
			},
			Processing: () => {
				/** no-op. No updates for processing */
			},
			Processed: () => {
				const areAllRejected = orderRequest.items.every(item => {
					return match(item.status, {
						Pending: () => false,
						Approved: () => false,
						Rejected: () => true
					});
				});
				// todo: cleanup, also we need to do something to
				// orders that are all rejected (cancelled maybe)?
				if (!areAllRejected && orderRequest.order_id) {
					ingests.push({
						Finalise: {
							id: orderRequest.order_id,
							target_deliver_before_timestamp: selection.delivery_date.valueOf(),
							items: orderRequest.items
								.map(item =>
									match<
										draft_order.DraftOrderItemStatus,
										draft_order.DraftOrderItemFinalise | null
									>(item.status, {
										Pending: () => null,
										Approved: ({ reason, details }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: () => {
													finalise.status = 'Approved';
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										},
										Rejected: ({ details, reason }) => {
											const finalise: draft_order.DraftOrderItemFinalise = {
												id: item.local_id,
												order_separately: item.order_separately,
												quantity: item.quantity,
												status: undefined,
												status_detail: undefined
											};

											match(reason, {
												Estimator: reason => {
													finalise.status = {
														Rejected: reason
													};
													finalise.status_detail = details;
												},
												Supplier: () => {
													/** no-op, remote is already correct */
												}
											});

											return finalise;
										}
									})
								)
								.filter(isDefined)
						}
					});
				}
			},
			Finalised: () => {
				/** no-op. End of flow  */
			}
		});
	}

	const ordersToDelete = Array.from(remoteIds.keys());
	for (const toDelete of ordersToDelete) {
		ingests.push({ Remove: toDelete });
	}

	return ingests;
};
