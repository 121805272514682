import { OfferSelection } from '../../models';
import { Card } from '@mantine/core';
import { EmptyState } from '@/app/atoms/empty-state';
import { offers_search } from '@partly/core-server-client';
import { useMemo } from 'react';
import { ProductOfferCard } from '../product-offer-card';
import { getAssembly, getBusiness, getOfferSellable } from '../../utils';
import { AssemblyOfferCard } from '../assembly-offer-card';

interface OfferColumnProps {
	condition: 'new' | 'used';
	offerSelection: OfferSelection;
	offers: offers_search.Offer[];
	deliverBefore: Date;
	onSelectionChange: (offer: offers_search.Offer) => void;
}

export const OfferColumn = ({
	condition,
	offerSelection,
	offers,
	deliverBefore,
	onSelectionChange
}: OfferColumnProps) => {
	const assemblyOffers = useMemo(
		() =>
			offers
				.filter(
					offer =>
						offer.type === 'single' &&
						(offer as offers_search.SellableOffer).payload.kind === 'assembly'
				)
				.map(assemblyOffer => ({
					offer: assemblyOffer,
					assembly: getAssembly(assemblyOffer)!,
					business: getBusiness(assemblyOffer)!
				})),
		[offers]
	);

	const productOffers = useMemo(
		() =>
			offers
				.filter(
					offer =>
						offer.type === 'single' &&
						(offer as offers_search.SellableOffer).payload.kind === 'product'
				)
				.map(productOffer => ({
					offer: productOffer,
					sellable: getOfferSellable(productOffer)!,
					business: getBusiness(productOffer)!
				})),
		[offers]
	);

	return (
		<div>
			<b> {condition === 'new' ? 'New' : 'Used'}</b>
			<ul className="flex flex-wrap w-full gap-6 mt-4">
				{offers.length ? (
					<>
						{productOffers.map(({ offer, sellable, business }) => (
							<li key={offer.id} className="w-full min-w-[380px] 2xl:w-[500px]">
								<ProductOfferCard
									deliverBefore={deliverBefore}
									isSelected={offerSelection.has(offer.id)}
									product={sellable}
									business={business}
									onSelectChange={() => onSelectionChange(offer)}
								/>
							</li>
						))}

						{assemblyOffers.map(({ offer, assembly, business }) => (
							<li key={offer.id} className="w-full min-w-[380px] 2xl:w-[500px]">
								<AssemblyOfferCard
									deliverBefore={deliverBefore}
									isSelected={offerSelection.has(offer.id)}
									assembly={assembly}
									business={business}
									onSelectChange={() => onSelectionChange(offer)}
								/>
							</li>
						))}
					</>
				) : (
					<li className="w-full min-w-[380px] 2xl:w-[500px]">
						<Card className="p-6 ring-1 ring-gray-200 border-gray-300 bg-gray-100 h-[186px] opacity-70 flex items-center justify-center rounded-md">
							<EmptyState className="bg-transparent ">
								<EmptyState.Title>No {condition} offers</EmptyState.Title>
								<EmptyState.Description>
									There are no {condition} offers for this job
								</EmptyState.Description>
							</EmptyState>
						</Card>
					</li>
				)}
			</ul>
		</div>
	);
};
