import { formatPrice } from '@/app/utils/currency';
import { Button, Card } from '@mantine/core';
import { offers_search, supply_search } from '@partly/core-server-client';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { ConditionBadge } from '../condition-badge';
import { FormattedShippingTime } from '../formatted-shipping-time';
import { formatOfferPrice } from '../../utils';

interface ProductOfferCardProps {
	product: offers_search.Product;
	business: supply_search.Business;
	isSelected: boolean;
	deliverBefore: Date;
	onSelectChange(isSelected: boolean): void;
}

export const ProductOfferCard = ({
	product,
	business,
	deliverBefore,
	onSelectChange,
	isSelected
}: ProductOfferCardProps) => {
	const handleSelectOffer = () => {
		onSelectChange(!isSelected);
	};

	return (
		<Card
			className="p-6 ring-gray-200 min-h-[186px] flex-col cursor-pointer ring-1 w-full flex gap-3 items-start rounded-md"
			aria-checked={isSelected}
			role="checkbox"
			onClick={handleSelectOffer}
		>
			<div className="flex w-full justify-between font-semibold">
				<b className="font-semibold">{business.name}</b>
				<span>{formatPrice(formatOfferPrice(product.price))} </span>
			</div>
			<div className="flex w-full text-xs justify-between text-gray-700">
				<FormattedShippingTime
					deliverBefore={deliverBefore}
					shippingTime={product.shipping_time || null}
				/>
				{product.entity.condition ? <ConditionBadge condition={product.entity.condition} /> : null}
			</div>
			<Button
				className="px-16 mt-auto"
				variant={isSelected ? 'filled' : 'outline'}
				leftIcon={isSelected && <CheckCircleIcon width={16} />}
			>
				{isSelected ? 'Selected' : 'Select'}
			</Button>
		</Card>
	);
};
