import { EmptyState } from '@/app/atoms/empty-state';
import { LocationDetails } from '@/app/common/components/molecules/location-details';
import { VehicleDetails } from '@/app/common/components/molecules/vehicle-details';
import { useAuthenticatedUser } from '@/app/common/hooks/use-me';
import { SupplySummaryToolbar } from '@/app/features/supply/components/supply-summary-toolbar';
import { useOrderRequestsDrawer } from '@/app/features/supply/hooks/use-order-request-drawer';
import {
	AddItemPayload,
	DraftOrderSelection,
	OrderRequestModel
} from '@/app/features/supply/models';
import { formatPrice } from '@/app/utils/currency';
import { tlsx } from '@/app/utils/tw-merge';
import { InheritableElementProps } from '@/types/utilties';
import { Transition } from '@headlessui/react';
import { Alert, Button } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { Job, JobPart } from '@sdk/lib';
import { addHours, isAfter, isBefore } from 'date-fns';
import { cloneElement, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Drawer } from 'vaul';
import { useNoSupplyParts } from '../../hooks/use-no-supply-parts';
import { useOrderRequestAggregation } from '../../hooks/use-order-request-aggregation';
import { getMinDeliveryDate, onAddExternalItem } from '../../order-request';
import { AddExternalItemModal } from '../add-external-item-modal';
import { OrderRequestBuilder } from '../order-request-builder';
import { OrderRequestNoSupply } from '../order-request-no-supply';

type DraftOrderSelectionDrawerProps = InheritableElementProps<
	'div',
	{
		job: Job;
		form: UseFormReturn<DraftOrderSelection>;
		noSupplyParts: JobPart[];
		onSave: (selection: DraftOrderSelection) => void;
		onRequestOrders: (data: DraftOrderSelection, instantAccept: boolean) => void;
		onCancel: (order: OrderRequestModel) => void;
	}
>;

export const OrderRequestsDrawer = ({
	form,
	job,
	noSupplyParts,
	onSave,
	onRequestOrders,
	onCancel
}: DraftOrderSelectionDrawerProps) => {
	const selection = form.watch();
	const slider = useOrderRequestsDrawer();
	const { totalItems, totalPrice, identities, totalSuppliers } =
		useOrderRequestAggregation(selection);
	const [params] = useSearchParams();
	const targetOrderId = params.get('order');
	const noSupply = useNoSupplyParts(noSupplyParts, identities);
	const ordersList = useMemo(
		() =>
			Object.values(selection.draft_orders)
				.sort((a, b) => {
					if (a.status === 'Draft' && b.status !== 'Draft') {
						return -1;
					}
					if (a.status !== 'Draft' && b.status === 'Draft') {
						return 1;
					}
					return b.created_at - a.created_at;
				})
				.map(order => ({
					...order,
					items: order.items.sort((a, b) => {
						return b.local_id.localeCompare(a.local_id);
					})
				})),
		[selection]
	);

	const onAddItem = (data: AddItemPayload) => {
		onAddExternalItem(selection, data, form);
	};

	return (
		<Drawer.Root
			snapPoints={slider.snapPoints}
			activeSnapPoint={slider.activeSnapPoint}
			open
			setActiveSnapPoint={slider.setActiveSnapPoint}
			dismissible={false}
			handleOnly
			modal={false}
		>
			<Drawer.Portal container={document.body}>
				<Transition
					show={slider.isFullOpen}
					enter="transition-opacity duration-300 ease-in"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-300 ease-out"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<button
						className="fixed inset-0 z-20 bg-black/40"
						aria-label="Close drawer"
						onClick={slider.closeDrawer}
					/>
				</Transition>
				<Drawer.Content
					ref={slider.ref}
					className="fixed flex flex-col bg-white border border-gray-200 border-b-none bottom-0 left-0 right-0 h-full z-30 outline-none shadow-[0_3px_34px_-15px_rgba(0,0,0,0.3)]"
				>
					<Drawer.Title className="sr-only">Orders builder</Drawer.Title>
					<Drawer.Description className="sr-only">
						Build orders from available supply
					</Drawer.Description>
					{cloneElement(<Drawer.Handle />, {
						preventCycle: true,
						className: 'w-12 h-1.5 mt-2 bg-gray-200 rounded-full',
						onDoubleClick: () => {
							/** removes broken close logic */
						}
					})}
					<div
						className={tlsx({ 'overflow-y-auto': slider.isOpen })}
						style={{ height: slider.scrollHeight }}
					>
						<div className="w-full px-6 pb-6">
							<SupplySummaryToolbar
								className="pt-2 pb-4 border-b bg-white sticky top-0 z-10"
								isExanded={slider.isOpen}
								totalCost={totalPrice}
								totalParts={totalItems}
								totalSuppliers={totalSuppliers}
								onClose={slider.closeDrawer}
								onSave={form.handleSubmit(onSave)}
								onViewRequest={slider.openDrawer}
							/>
							<form className="mx-auto grid grid-cols-10 max-w-7xl gap-6 mt-6" noValidate>
								<div className="col-span-7 space-y-6">
									{ordersList.length === 0 ? (
										<EmptyState>
											<EmptyState.Title>No orders</EmptyState.Title>
											<EmptyState.Description>
												Start by selecting parts from the supply options
											</EmptyState.Description>
										</EmptyState>
									) : (
										ordersList.map(order => (
											// todo: use controller / form control to make
											// this more performant. Controller has bug with stale data,
											// currently re-renders all the time :(
											// will circle back to fix.
											<OrderRequestBuilder
												key={order.local_id}
												id={`order-${order.local_id}`}
												className={tlsx({
													'ring-2 ring-theme-600': order.local_id === targetOrderId
												})}
												model={order}
												onCancel={onCancel}
												onChange={val => {
													if (!val) {
														delete selection.draft_orders[order.local_id];
														form.setValue(`draft_orders`, selection.draft_orders);
														return;
													}

													form.setValue(`draft_orders.${order.local_id}`, val);
												}}
												onAddItem={onAddItem}
											/>
										))
									)}
									<OrderRequestNoSupply noSupply={noSupply} onAddItem={onAddItem} />
								</div>
								<ConfirmationAside
									className="self-start col-span-3"
									form={form}
									job={job}
									totalItems={totalItems}
									totalPrice={totalPrice}
									selection={selection}
									onRequestOrders={onRequestOrders}
								/>
							</form>
						</div>
					</div>
				</Drawer.Content>
			</Drawer.Portal>
		</Drawer.Root>
	);
};

type ConfirmationAsideProps = InheritableElementProps<
	'aside',
	{
		job: Job;
		form: UseFormReturn<DraftOrderSelection>;
		selection: DraftOrderSelection;
		totalItems: number;
		totalPrice: number;
		onRequestOrders: (data: DraftOrderSelection, instantAccept: boolean) => void;
	}
>;

const ConfirmationAside = ({
	form,
	selection,
	job,
	totalItems,
	totalPrice,
	className,
	onRequestOrders,
	...rest
}: ConfirmationAsideProps) => {
	const { sites } = useAuthenticatedUser();
	const [addExternalItem, setAddExternalItem] = useState(false);

	const latestDeliveryDate = useMemo(() => {
		const models = Object.values(selection.draft_orders);
		return getMinDeliveryDate(models);
	}, [selection]);

	const isDeliveryWithin24Hours = useMemo(() => {
		const now = new Date();
		const next24Hours = addHours(now, 24);
		return isAfter(selection.delivery_date, now) && isBefore(selection.delivery_date, next24Hours);
	}, [selection.delivery_date]);

	const onAddItem = (data: AddItemPayload) => {
		setAddExternalItem(false);
		onAddExternalItem(selection, data, form);
	};

	const confirmState = useMemo(() => {
		// todo: cleanup
		const values = Object.values(selection.draft_orders).filter(item => {
			// You can't send finalised orders again
			if (item.status === 'Finalised') {
				return false;
			}

			// Cancelled orders can't be sent
			if (typeof item.status === 'object' && 'Cancelled' in item.status) {
				return false;
			}

			if (item.items.every(item => typeof item.status === 'object' && 'Rejected' in item.status)) {
				return false;
			}

			return true;
		});

		if (values.length === 0) {
			return { sendOrders: false, orderRequest: false };
		}

		const hasDraft = values.some(item => item.status === 'Draft');
		const hasProcessed = values.some(item => item.status === 'Processed');

		return {
			sendOrders: hasDraft || hasProcessed,
			orderRequest: hasDraft
		};
	}, [selection]);

	const site = sites.find(site => site.id === job.repairerSiteId);

	return (
		<aside className={tlsx('p-6 space-y-4 border rounded-md', className)} {...rest}>
			<div className="flex items-center justify-between">
				<h1 className="text-lg font-semibold">Orders</h1>
				<Button size="xs" variant="default" onClick={() => setAddExternalItem(true)}>
					Add item
				</Button>
			</div>
			<VehicleDetails className="p-3 border rounded-md" vehicle={job.vehicle} />
			<LocationDetails className="p-3 border rounded-md" site={site} />
			<Controller
				control={form.control}
				name="delivery_date"
				render={({ field }) => (
					<DateTimePicker
						label="Delivery date"
						disabled={!confirmState.orderRequest && !confirmState.sendOrders}
						minDate={latestDeliveryDate}
						{...field}
						// typescript being weird, don't have
						// time to investigate
						onPointerLeaveCapture={undefined}
						onPointerEnterCapture={undefined}
						placeholder={undefined}
					/>
				)}
			/>
			{isDeliveryWithin24Hours && (
				<Alert color="blue" title="Upcoming delivery">
					Delivery is within next 24 hours.
				</Alert>
			)}
			<dl className="p-3 space-y-2 border rounded-md">
				<div className="flex items-center justify-between text-sm text-gray-700">
					<dt>Items</dt>
					<dd>{totalItems}</dd>
				</div>
				<div className="flex items-center justify-between text-sm font-medium text-gray-900">
					<dt>Total cost</dt>
					<dd>{formatPrice(totalPrice)}</dd>
				</div>
			</dl>
			<Button
				type="submit"
				color="green"
				className="w-full"
				loading={form.formState.isSubmitting}
				onClick={form.handleSubmit(d => onRequestOrders(d, true))}
				disabled={!confirmState.sendOrders}
			>
				Send orders
			</Button>
			<Button
				type="submit"
				variant="default"
				className="w-full mt-2"
				loading={form.formState.isSubmitting}
				onClick={form.handleSubmit(d => onRequestOrders(d, false))}
				disabled={!confirmState.orderRequest}
			>
				Request orders
			</Button>
			<p className="text-xs text-gray-600">
				Order requests are sent to the supplier for processing, but are not ordered
			</p>
			<AddExternalItemModal
				open={addExternalItem}
				onClose={() => setAddExternalItem(false)}
				onSubmit={onAddItem}
			/>
		</aside>
	);
};
