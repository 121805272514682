import { createPartSlotIds } from '@/app/utils/part';
import { GapcDiagram, GapcDiagramPartSlot, PartAssembly } from '@/sdk/lib';
import { compact, entries, groupBy, isNil, partition, sortBy, uniqBy, values } from 'lodash-es';
import { HCA_TOP_LAYERS } from '../constants';
import {
	CategoryTree,
	CategoryTreeLeaf,
	CategoryTreeNode,
	Diagram,
	DiagramAssembly,
	DiagramAssemblyResources,
	DiagramHotspotMesh,
	DiagramInfo,
	DiagramLineMesh,
	DiagramPartSlot,
	DiagramPolygonMesh,
	DiagramWhiteoutMesh
} from '../types';
import { assignDiagramPartSlotCode } from './code';
import { line2ToCenterPoint, line2ToRect, vec2 } from './geometry';
import { drawLines } from './mesh';
import { partSlotClassificationSortKey, partSlotHcaSortKey } from './sort';
import { transformTag } from './variant';

export const slice = <T extends any[]>(args: readonly [...T]) => args as T;

export const categoriesDiagrams = (_assemblies: PartAssembly[]) => {
	const trimmed = compact(_assemblies.map(trimPartAssembly));
	const [hcas, relevants] = partition(
		trimmed,
		({ assemblyType }) => assemblyType === 'human_centric'
	);

	const resources = createAssemblyResources(
		trimmed.flatMap(assembly => transformDiagramAssembly(assembly)),
		trimmed
			.flatMap(assembly => getAllDiagrams(assembly))
			.map(diagram => ({
				id: diagram.id,
				code: diagram.code,
				description: diagram.name,
				image: {
					full: diagram.image.large,
					thumb: diagram.image.thumb
				}
			}))
	);
	const categories = transformTopLayerCategoryTree(hcas, resources);

	const other = transformOtherCategoryTree(relevants, resources);

	return { categories, other, resources };
};

const createAssemblyResources = (assemblies: DiagramAssembly[], diagrams: DiagramInfo[]) => {
	return {
		assemblies: new Map(assemblies.map(assembly => slice([assembly.id, assembly]))),
		parts: new Map(
			entries(groupBy(assemblies, assembly => assembly.part.partIdentity)).map(
				([partIdentity, assemblies]) => [partIdentity, uniqBy(assemblies, ({ id }) => id)] as const
			)
		),
		diagrams: new Map(diagrams.map(diagram => slice([diagram.id, diagram]))),
		figures: new Map(
			entries(groupBy(diagrams, diagram => diagram.code.replace('-', '').toLowerCase())).map(
				([code, diagrams]) => [code, uniqBy(diagrams, ({ id }) => id)] as const
			)
		)
	};
};

export const categoryLeaves = (category: CategoryTree): CategoryTreeLeaf[] => {
	if (category.kind === 'leaf') {
		return [category];
	}
	return category.assemblies.flatMap(categoryLeaves);
};

const transformTopLayerCategoryTree = (
	hcas: PartAssembly[],
	resources: DiagramAssemblyResources
): CategoryTreeNode[] => {
	const [cuts, others] = partition(hcas, ({ description }) =>
		HCA_TOP_LAYERS.map(layer => layer.toLowerCase()).includes(description.toLowerCase())
	);

	const other: CategoryTreeNode = {
		kind: 'node',
		id: 'other',
		description: 'Others',
		assemblies: compact(others.map(other => transformCategoryTree(other, resources, []))),
		hcas: [],
		searchables: []
	};

	const categories = sortBy(
		cuts.map((cut): CategoryTreeNode => {
			const assemblies = transformSubcategoryTree(cut, resources, []);
			return {
				kind: 'node',
				id: cut.id,
				description: cut.description,
				hcas: [],
				assemblies,
				searchables: [cut.description]
			};
		}),
		({ description }) => description
	);

	if (other.assemblies.length === 0) {
		return categories;
	}
	return [...categories, other];
};

const transformCategoryTree = (
	hca: PartAssembly,
	resources: DiagramAssemblyResources,
	hcas: string[]
): CategoryTree => {
	// leaf is the last possible category which will have diagrams on it instead of more tree node
	// figuring a leaf dynamically (O2T1 + O2T3):
	// - if a node is not a "Cut", and
	// - if a node has child assemblies that are parts, or
	// - if a node is a part, or
	// - if a node is layer 2 or deeper (higher number, lower layer)
	const isFinalLayer = isLeafPart(hca);
	const isTooDeep = hcas.length + 1 >= 2;
	const isCut = hca.description.includes('Cut');

	const isLastCategory = hca.subAssemblies.filter(isLeafPart).length > 0;

	const isLeaf = !isCut && (isFinalLayer || isTooDeep || isLastCategory);

	if (isLeaf) {
		const diagrams = sortBy(
			uniqBy(getAllDiagrams(hca), ({ id }) => id).map(diagram =>
				transformDiagram(diagram, resources, [...hcas, hca.description])
			),
			({ code }) => code
		);
		return {
			kind: 'leaf',
			id: hca.id,
			description: hca.description,
			diagrams,
			hcas,
			searchables: [hca.description]
		};
	}

	const assemblies = transformSubcategoryTree(hca, resources, hcas);

	return {
		kind: 'node',
		id: hca.id,
		description: hca.description,
		hcas,
		assemblies,
		searchables: [hca.description]
	};
};

const transformSubcategoryTree = (
	hca: PartAssembly,
	resources: DiagramAssemblyResources,
	hcas: string[]
) => {
	return sortBy(
		compact(
			values(groupBy(hca.subAssemblies, ({ id, hca }) => hca ?? id)).map(assemblies => {
				if (assemblies.length === 1) {
					return transformCategoryTree(assemblies[0], resources, [...hcas, hca.description]);
				}
				const diagrams = assemblies.flatMap(({ diagrams }) => diagrams ?? []);
				return transformCategoryTree({ ...assemblies[0], diagrams }, resources, [
					...hcas,
					hca.description
				]);
			})
		),
		({ kind }) => (kind === 'node' ? -1 : 1),
		({ description }) => description
	);
};

const transformOtherCategoryTree = (
	relevants: PartAssembly[],
	resources: DiagramAssemblyResources
): CategoryTreeLeaf => {
	const diagrams = sortBy(
		uniqBy(relevants.flatMap(getAllDiagrams), ({ id }) => id),
		({ code }) => code,
		({ name }) => name
	).map(diagram => transformDiagram(diagram, resources));

	return {
		kind: 'leaf',
		id: 'rlvt',
		description: 'Other diagrams',
		diagrams,
		searchables: diagrams.flatMap(({ searchables }) => searchables),
		hcas: []
	};
};

const transformDiagram = (
	diagram: GapcDiagram,
	resources: DiagramAssemblyResources,
	hcas: string[] = []
): Diagram => {
	const partSlots = [
		...compact(
			diagram.partSlots.map(({ id, parts, ...partSlot }, index): DiagramPartSlot => {
				const code = `${index + 1}`;
				const assemblies = transformDiagramPartSlotAssemblies(
					{ id, parts, ...partSlot },
					resources,
					hcas.at(0)
				).map(({ code: _, ...rest }) => ({ ...rest, code }));

				const hotspots = partSlot.hotspots.map(({ x1Px, x2Px, y1Px, y2Px }) =>
					slice([vec2(x1Px, y1Px), vec2(x2Px, y2Px)])
				);

				const polygons = partSlot.segments.map(({ vectors }) =>
					vectors.map(({ x, y }) => vec2(x, y))
				);

				const lines = drawLines(hotspots, polygons);

				return {
					kind: 'assembly',
					id,
					code,
					pnc: partSlot.code,
					assemblies,
					meshes: {
						whiteouts: hotspots.map(
							(line): DiagramWhiteoutMesh => ({ kind: 'whiteout', rect: line2ToRect(line) })
						),
						lines: lines.map(
							([from, to]): DiagramLineMesh => ({
								kind: 'line',
								from,
								to
							})
						),
						polygons: polygons.map((polygon): DiagramPolygonMesh => ({ kind: 'polygon', polygon })),
						hotspots: hotspots.map(
							(line): DiagramHotspotMesh => ({ kind: 'hotspot', point: line2ToCenterPoint(line) })
						)
					}
				};
			})
		),
		...compact(
			diagram.references.map(({ id, figureId, ...reference }, index): DiagramPartSlot | null => {
				const code = String.fromCharCode(index + 65);
				const hotspot = slice([
					vec2(reference.hotspot.x1Px, reference.hotspot.y1Px),
					vec2(reference.hotspot.x2Px, reference.hotspot.y2Px)
				]);

				const polygons = reference.segments.map(({ vectors }) =>
					vectors.map(({ x, y }) => vec2(x, y))
				);

				const lines = polygons.map(polygon => slice([[line2ToCenterPoint(hotspot)], polygon]));
				const diagrams = resources.figures.get(figureId.replace('-', '').toLowerCase()) ?? [];

				if (diagrams.length === 0) {
					return null;
				}

				return {
					kind: 'reference',
					id,
					code,
					figure: figureId.replace('-', '').toLowerCase(),
					diagrams,
					meshes: {
						whiteouts: [{ kind: 'whiteout', rect: line2ToRect(hotspot) }],
						lines: lines.map(
							([from, to]): DiagramLineMesh => ({
								kind: 'line',
								from,
								to
							})
						),
						polygons: polygons.map((polygon): DiagramPolygonMesh => ({ kind: 'polygon', polygon })),
						hotspots: [{ kind: 'hotspot', point: line2ToCenterPoint(hotspot) }]
					}
				};
			})
		)
	];

	return {
		id: diagram.id,
		code: diagram.code,
		description: diagram.name,
		fitment: diagram.fitment,
		image: {
			full: diagram.image.large,
			thumb: diagram.image.thumb
		},
		hcas,
		searchables: [diagram.name],
		partSlots: assignDiagramPartSlotCode(
			sortBy(partSlots, partSlotClassificationSortKey, partSlotHcaSortKey)
		)
	};
};

const transformDiagramPartSlotAssemblies = (
	{ id, parts, ...partSlot }: GapcDiagramPartSlot,
	resources: DiagramAssemblyResources,
	hca?: string
) => {
	// dedup if any same HCA with the same part number as it's unnecessary for a part slot (should not exist)
	if (partSlot.assemblies.length > 0) {
		return uniqBy(
			compact(partSlot.assemblies.map(id => resources.assemblies.get(id))),
			({ description, hca, part }) => `${part.partIdentity}/${hca ?? description}`
		);
	}

	const assemblies = compact(
		parts.flatMap(({ partIdentity }) => (partIdentity ? resources.parts.get(partIdentity) : []))
	);

	// if it's not in an hca for some reason, show unique ones by mpn
	if (!hca) {
		return uniqBy(assemblies, ({ part }) => part.partIdentity);
	}

	// if for an hca, smartly not have multiple matches that are irrelevant
	const filtered = assemblies.filter(({ hcas }) => hcas.includes(hca));

	if (filtered.length > 0) {
		return uniqBy(
			uniqBy(filtered, ({ id }) => id),
			({ part }) => part.partIdentity
		);
	}

	// fallback to show unique by mpn
	return uniqBy(assemblies, ({ part }) => part.partIdentity);
};

const transformDiagramAssembly = (
	assembly: PartAssembly,
	hcas: string[] = []
): DiagramAssembly[] => {
	const assemblies: DiagramAssembly[] = [];

	// is part also
	if (!isNil(assembly.part)) {
		const part: DiagramAssembly = {
			id: assembly.id,
			description: assembly.description,
			hca: assembly.hca,
			code: '',

			part: assembly.part,
			partSlot: assembly.partSlot,
			partSlotIds: createPartSlotIds(assembly.partSlot),
			hcas,
			searchables: [assembly.description, assembly.part.mpn],

			confidence: assembly.confidence,
			availability: assembly.supply?.availability,
			grades: assembly.supply?.grades,
			tags: compact(assembly.tags?.map(transformTag) ?? [])
		};

		assemblies.push(part);
	}

	assemblies.push(
		...compact(
			assembly.subAssemblies.flatMap(sub =>
				transformDiagramAssembly(sub, [...hcas, assembly.description])
			)
		)
	);

	return assemblies;
};

const trimPartAssembly = (assembly: PartAssembly): PartAssembly | null => {
	const subAssemblies = compact(assembly.subAssemblies.map(trimPartAssembly));

	// no child, no part attached
	if (subAssemblies.length === 0 && isNil(assembly.part)) {
		return null;
	}

	return {
		...assembly,
		subAssemblies
	};
};

// get all diagrams recursively in the assembly (need to stop getting diagram based on mpn, loses context on multi-purposes)
const getAllDiagrams = (assembly: PartAssembly): GapcDiagram[] => {
	const diagrams = (assembly.diagrams ?? []).map(diagram => ({
		...diagram,
		partSlots: diagram.partSlots
	}));

	const more = assembly.subAssemblies.flatMap(sub => getAllDiagrams(sub));

	return uniqBy([...diagrams, ...more], ({ id }) => id);
};

const isLeafPart = (hca: PartAssembly) => {
	return !isNil(hca.part);
};
