import SupplyHeader from '@/app/common/components/molecules/supply-header';
import { OrderRequestsDrawer } from '@/app/features/supply/components/order-request-drawer';
import { PartsListSidebar } from '@/app/features/supply/components/parts-list-sidebar';
import { SupplyDetails } from '@/app/features/supply/components/supply-details';
import { useBackgroundDraftOrderRefetch } from '@/app/features/supply/hooks/use-background-draft-order-refetch';
import { usePartOfferAgregations } from '@/app/features/supply/hooks/use-part-offer-agregations';
import { useSupplyStyles } from '@/app/features/supply/hooks/use-supply-styles';
import {
	DraftOrderSelection,
	EnrichedJobPart,
	OrderRequestModel,
	PartOfferAggregation
} from '@/app/features/supply/models';
import {
	applyOfferSelection,
	buildIngestFromSelection as buildEntitiesFromSelection
} from '@/app/features/supply/order-request';
import { createSelection, getRecommendedOffers } from '@/app/features/supply/utils';
import { useAnalytics } from '@/app/hooks/use-analytics';
import { useOfferSelection } from '@/app/hooks/use-offer-selection';
import { useUnsavedChanges } from '@/app/hooks/use-unsaved-changes';
import { useWatchForm } from '@/app/hooks/use-watch-form';
import { jobsQueries } from '@/sdk/react';
import { mutations } from '@/sdk/react/mutations';
import { bmsMutations } from '@/sdk/react/mutations/bms';
import { queries } from '@/sdk/react/queries';
import { api_error } from '@/sdk/reflect/reflect';
import { match } from '@/types/match';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Button } from '@mantine/core';
import { RepairApp } from '@partly/analytics';
import { offers_search } from '@partly/core-server-client';
import { useMutation, useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { JobMainAction } from '../job-detail';
import { jobPartOfferSearch } from './core-client';

const onOrderIngestError = (e: api_error.RepairerServerError) => {
	match(
		e,
		{
			BadRequest: e => {
				toast.error(e);
			},
			InternalServerError: e => {
				toast.error(`An unexpected error occurred: ${e}`);
			},
			NotFound: e => {
				toast.error(`Resource not found: ${e}`);
			},
			Application: _ => {
				/** No-op */
			},
			Unauthorized: _ => {
				/** No-op */
			}
		},
		() => {
			toast.error('An unexpected error occurred');
		}
	);
};

type PageParams = {
	jobId: string;
};

export const SupplyPage = () => {
	const { jobId } = useParams<PageParams>();
	const initialMount = useRef(true);

	if (!jobId) {
		throw new Error('Missing required jobId parameter');
	}

	const { sidebarId, sidebarStyles, containerStyles } = useSupplyStyles();
	const analytics = useAnalytics();
	const navigate = useNavigate();

	const {
		mutateAsync: syncJobParts,
		isSuccess,
		reset: resetBmsSyncState
	} = useMutation({
		...bmsMutations.syncJobParts,
		onSuccess: () => {
			toast.success('Parts are updated in iBodyShop');
		}
	});

	const { mutateAsync: ingestOrders } = useMutation({
		...mutations.draft_orders.ingest,
		onError: onOrderIngestError
	});

	// We set gc time to 0 so that the queries are immedietly garbage collected
	// after component is removed. The data should only update is going back to
	// parts page or if a save occurs.
	const [{ data: jobData }, { data: drafOrderData }] = useSuspenseQueries({
		queries: [
			{ ...jobsQueries.get({ jobId }), refetchOnMount: false },
			{ ...queries.draft_orders.list({ job_id: jobId }), gcTime: 0 }
		]
	});

	// Custom query combining job parts and offers
	const { data: offerData } = useSuspenseQuery(
		jobPartOfferSearch({ job_id: jobId, repairerSiteId: jobData.job.repairerSiteId })
	);

	const aggregations = usePartOfferAgregations(
		offerData.job_parts,
		offerData.matches,
		offerData.offers as offers_search.Offer[]
	);

	const form = useForm<DraftOrderSelection>({
		defaultValues: createSelection(
			drafOrderData.draft_orders,
			aggregations,
			getRecommendedOffers(offerData, 'best'),
			jobData.job.repairerSiteId
		)
	});

	const [selectedRecommendationType, setSelectedRecommendationType] = useState<
		keyof offers_search.RecommendedBaskets | null
	>('best');
	const [selectedPart, setSelectedPart] = useState<PartOfferAggregation | null>(aggregations[0]);

	const formSelection = useWatchForm(form);
	const offerSelection = useOfferSelection(formSelection);

	useUnsavedChanges(({ nextLocation }) => {
		const isConfirmation = nextLocation.pathname.startsWith(`/job/${jobId}/orders`);
		const isBlocking = form.formState.isDirty && !form.formState.isSubmitting && !isConfirmation;
		return isBlocking;
	});

	useBackgroundDraftOrderRefetch(jobId, form);

	const onRecommendationSelect = (recommendationType: typeof selectedRecommendationType) => {
		setSelectedRecommendationType(recommendationType);

		const newSelection = createSelection(
			[],
			aggregations,
			getRecommendedOffers(offerData, recommendationType!),
			jobData.job.repairerSiteId
		);

		form.reset(newSelection);
		resetBmsSyncState();
	};

	// Effects
	useEffect(() => {
		if (
			!selectedPart ||
			!aggregations.some(ag => ag.jobPart.transientId === selectedPart.jobPart.transientId)
		) {
			setSelectedPart(aggregations[0]);
		}
	}, [aggregations]);

	useEffect(() => {
		if (initialMount.current) {
			// apply default selection based on recommendation
			onRecommendationSelect('best');
			initialMount.current = false;
		}
	}, [offerData.recommendations]);

	const onOfferSelect = (jobPart: EnrichedJobPart, offer: offers_search.Offer) => {
		applyOfferSelection(jobPart, offer, form);

		// TODO: handle quantity check on FE

		resetBmsSyncState();
	};

	// todo: object config
	const onRequestOrders = async (
		data: DraftOrderSelection,
		instantAccept: boolean,
		process: boolean
	) => {
		if (!offerData.snapshot_hash) {
			// This should never happen, you can't make a selection
			// without having supply options.
			throw new Error('Supply hash id is missing');
		}

		const entities = buildEntitiesFromSelection(
			data,
			drafOrderData.draft_orders,
			offerData.snapshot_hash,
			instantAccept
		);

		const result = await ingestOrders({
			repairer_site_id: data.repairer_site_id,
			job_id: jobId,
			entities,
			options: {
				/// Whether or not processed drafts
				/// should be finalised transitioned into real orders.
				finalise_processed: process,
				/// Whether or not draft orders should be transitioned
				/// into processing.
				process_drafts: process
			}
		});

		if (!process) {
			analytics.logEvent(
				RepairApp.supply_selection.supply_finalised({
					num_suppliers: result.draft_orders.length
					// don't have this data
					// num_new_parts: 0,
					// num_used_parts: 0
				})
			);
		}
		if (process) {
			result.draft_orders.forEach(order => {
				// This method is called with all orders even ones previously cancelled.
				if (typeof order.status === 'object' && 'Cancelled' in order.status) {
					return;
				}

				const ctx = {
					order_id: order.id,
					supplier_id: order.vendor.Partner.id,
					supplier_name: order.vendor.Partner.name,
					order_value: order.items.reduce((acc, item) => acc + (item.price ?? 0), 0),
					num_skus: order.items.length
				};

				// TODO bug - this will get called again on previously finalised orders
				if (order.status === 'Finalised') {
					analytics.logEvent(
						RepairApp.orders.order_finalised({
							...ctx,
							order_status: 'FINALISED'
						})
					);
				} else {
					analytics.logEvent(
						RepairApp.orders.order_sent({
							...ctx,
							order_status: 'PLACED'
						})
					);
				}
			});
		}

		const newState = createSelection(
			result.draft_orders,
			aggregations,
			getRecommendedOffers(offerData, 'fastest'),
			jobData.job.repairerSiteId
		);

		form.reset(newState);
		drafOrderData.draft_orders = result.draft_orders;
		resetBmsSyncState();
		toast.success(`Changes ${process ? 'sent' : 'saved'}`);

		if (process) {
			navigate(`/job/${jobId}`);
		}
	};

	const onCancel = async (request: OrderRequestModel) => {
		// Cancel is special case, we cancel immedietly and
		// we don't want to override any other potentially pending
		// changes.
		const result = await ingestOrders({
			repairer_site_id: jobData.job.repairerSiteId!,
			job_id: jobId,
			entities: [
				{
					Cancel: request.local_id
				}
			],
			options: {}
		});

		analytics.logEvent(
			RepairApp.orders.order_cancelled({
				order_id: request.local_id,
				supplier_id: request.vendor.Partner.id,
				supplier_name: request.vendor.Partner.name,
				order_status: 'CANCELLED'
			})
		);

		const newState = result.draft_orders.find(order => order.id === request.local_id);
		const existingState = form.getValues().draft_orders[request.local_id];
		if (!existingState || !newState) {
			return;
		}

		// These are the only fields that can change.
		existingState.status = newState.status;
		existingState.updated_at = newState.updated_at;
		form.setValue(`draft_orders.${request.local_id}`, existingState);
	};

	const onSyncBms = async () => {
		await syncJobParts({ jobId });
	};

	return (
		// fix -m-6 caused by parent
		<div className="flex flex-col flex-1 -m-6">
			{jobData.job.bmsIntegrated && (
				<JobMainAction>
					<div className="flex justify-end w-full">
						<Button
							size="sm"
							variant="subtle"
							onClick={onSyncBms}
							disabled={!jobData.job.bmsIntegrated || isSuccess}
							leftIcon={<ArrowPathIcon className="w-4 h-4" />}
						>
							Sync to iBodyShop
						</Button>
					</div>
				</JobMainAction>
			)}
			<SupplyHeader
				form={form}
				selectedRecommendationType={selectedRecommendationType}
				onRecommendationSelect={onRecommendationSelect}
			/>
			<div className="flex flex-1">
				<PartsListSidebar
					id={sidebarId}
					style={sidebarStyles}
					deliverBefore={formSelection.deliver_before}
					className="w-full max-w-sm flex-shrink-0 sticky"
					partOfferAggregations={aggregations}
					offerSelection={offerSelection}
					selectedId={selectedPart?.jobPart.transientId}
					onSelect={setSelectedPart}
				/>
				<div className="flex-1 border-l border-t rounded-tl-md bg-gray-50" style={containerStyles}>
					<SupplyDetails
						deliverBefore={formSelection.deliver_before}
						partOfferAggregation={selectedPart}
						offerSelection={offerSelection}
						onSelectionChange={onOfferSelect}
					/>
				</div>
			</div>
			<OrderRequestsDrawer
				job={jobData.job}
				form={form}
				noSupplyParts={[]}
				onSave={data => onRequestOrders(data, false, false)}
				onRequestOrders={(data, instantAccept) => onRequestOrders(data, instantAccept, true)}
				onCancel={onCancel}
			/>
		</div>
	);
};
