import { DraftOrderSelection, OfferSelection } from '@/app/features/supply/models';
import { OfferId } from '@/sdk/generated/models/OfferId';
import { useMemo } from 'react';

export const useOfferSelection = (selection: DraftOrderSelection): OfferSelection => {
	const offerSelection = useMemo<OfferSelection>(() => {
		const values = Object.values(selection.draft_orders).filter(value => value.status === 'Draft');
		const offerIds = new Set<OfferId>();
		for (const value of values) {
			for (const item of value.items) {
				if (item.buyable.type === 'Listing') {
					offerIds.add(item.buyable.offer.listing_id);
				} else if (item.buyable.type === 'Sellable') {
					offerIds.add(item.buyable.sellable_id);
				}
			}
		}

		return offerIds;
	}, [selection]);

	return offerSelection;
};
