import { coreSdk, repairSdk } from '@/sdk/reflect';
import { api_error, draft_order_handler, order_handler } from '@/sdk/reflect/reflect';
import { auth, business_network, NonPaginatedList } from '@partly/core-server-client';
import { queryOptions } from '@tanstack/react-query';
import { getConfig } from '../config';
import { intercept } from '../interceptor';

export const queries = {
	draft_orders: {
		list: (payload: draft_order_handler.DraftOrdersListRequest) =>
			queryOptions<draft_order_handler.exp.DraftOrderListResponse, api_error.RepairerServerError>({
				queryKey: ['draft-orders', 'list', payload],
				queryFn: () =>
					intercept(async () => {
						const { data, error } = await repairSdk(getConfig()).draft_orders.list(payload);
						if (error) {
							throw error;
						}

						return data;
					})
			})
	},
	orders: {
		list: (payload: order_handler.OrdersListRequest) =>
			queryOptions<order_handler.exp.OrdersListResponse, api_error.RepairerServerError>({
				queryKey: ['orders', 'list', payload],
				queryFn: () =>
					intercept(async () => {
						const { data, error } = await repairSdk(getConfig()).orders.list(payload);
						if (error) {
							throw error;
						}

						return data;
					})
			})
	},
	users: {
		get: (payload: auth.UsersGetRequest) =>
			queryOptions<auth.UsersGetResponse, auth.UsersGetError>({
				queryKey: ['users', 'get'],
				queryFn: () =>
					intercept(async () => {
						const [api, headers] = coreSdk(getConfig());
						const { authorization } = headers;
						const result = await api.users.get(payload, { authorization: authorization ?? '' });
						if (result.is_err()) {
							throw result.unwrap_err();
						}

						return result.unwrap_ok();
					})
			})
	},
	organizations: {
		list: (payload: object = {}) =>
			queryOptions<NonPaginatedList<auth.OrganizationModel>, auth.OrganizationsListError>({
				queryKey: ['organizations', 'list'],
				queryFn: () =>
					intercept(async () => {
						const [api, headers] = coreSdk(getConfig());
						const { authorization } = headers;
						const result = await api.organizations.list(payload, {
							authorization: authorization ?? ''
						});
						if (result.is_err()) {
							throw result.unwrap_err();
						}

						return result.unwrap_ok();
					})
			})
	},
	businesses: {
		list: (payload: business_network.BusinessesListRequest) =>
			queryOptions<business_network.BusinessesListResponse, business_network.BusinessesListError>({
				queryKey: ['businesses', 'list', payload],
				queryFn: () =>
					intercept(async () => {
						const [api, headers] = coreSdk(getConfig());
						const { authorization } = headers;
						const result = await api.businesses.list(payload, {
							authorization: authorization ?? ''
						});
						if (result.is_err()) {
							throw result.unwrap_err();
						}

						return result.unwrap_ok();
					})
			}),
		associations: {
			list: (payload: business_network.AssociationsListRequest) =>
				queryOptions<
					business_network.AssociationsListResponse,
					business_network.AssociationsListError
				>({
					queryKey: ['businesses', 'associations', 'list', payload],
					queryFn: () =>
						intercept(async () => {
							const [api, headers] = coreSdk(getConfig());
							const { authorization } = headers;
							const result = await api.businesses.associations.list(payload, {
								authorization: authorization ?? ''
							});
							if (result.is_err()) {
								throw result.unwrap_err();
							}

							return result.unwrap_ok();
						})
				})
		}
	}
};
