import { useMeasurement } from '@/app/hooks/use-measure';
import { useDeferredValue, useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';

const SIDEBAR_ID = 'supply-sidebar';
// todo: we should calculate this dynamically, but for now deadlines
// so it is hardcoded
const DEFAULT_DRAWER_HEIGHT = 76;

export const useSupplyStyles = () => {
	const { value } = useMeasurement('navigation-bar');
	const [offset, setOffset] = useState(0);
	const deferredOffsert = useDeferredValue(offset);
	const bodyHeight = use100vh() ?? window.innerHeight;

	useEffect(() => {
		const scrollFn = () => {
			const element = document.getElementById(SIDEBAR_ID);
			if (!element) {
				return;
			}

			const { top } = element.getBoundingClientRect();
			setOffset(top);
		};

		window.addEventListener('scroll', scrollFn);

		// When we mount make sure that we have the correct offset
		scrollFn();
		return () => window.removeEventListener('scroll', scrollFn);
	}, []);

	const navHeight = value?.height ?? 0;
	const sidebarHeight = bodyHeight - deferredOffsert - DEFAULT_DRAWER_HEIGHT;

	return {
		sidebarId: SIDEBAR_ID,
		containerStyles: {
			paddingBottom: DEFAULT_DRAWER_HEIGHT
		},
		sidebarStyles: {
			top: navHeight,
			height: sidebarHeight
		}
	};
};
