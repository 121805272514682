import { cloneDeep, isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * React hook forms watch() function is optimised for
 * render, not dependency hooks. This hook stabiles
 * the value of the form and returns it.
 */
export const useWatchForm = <T extends FieldValues>(form: UseFormReturn<T>) => {
	const [selection, setSelection] = useState<T>(form.getValues());

	// We make a copy of the selection as we cannot assume
	// that the value will not be accidentlly mutated.
	const previousSelection = useRef(selection);

	useEffect(() => {
		const { unsubscribe } = form.watch(value => {
			if (isEqual(value, previousSelection.current)) {
				return;
			}

			previousSelection.current = cloneDeep(value as T);
			setSelection(value as T);
		});
		return () => unsubscribe();
	}, [form.watch]);

	return selection;
};
