/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { BmsService } from './services/BmsService';
import { JobsService } from './services/JobsService';
import { SearchService } from './services/SearchService';
import { VehiclesService } from './services/VehiclesService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class ClientAPI {
	public readonly bms: BmsService;
	public readonly jobs: JobsService;
	public readonly search: SearchService;
	public readonly vehicles: VehiclesService;
	public readonly request: BaseHttpRequest;
	constructor(
		config?: Partial<OpenAPIConfig>,
		HttpRequest: HttpRequestConstructor = FetchHttpRequest
	) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '0.1.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH
		});
		this.bms = new BmsService(this.request);
		this.jobs = new JobsService(this.request);
		this.search = new SearchService(this.request);
		this.vehicles = new VehiclesService(this.request);
	}
}
