import { Badge } from '@/app/atoms/badge';
import clsx from 'clsx';

const tailwindColors = [
	'!bg-orange-400',
	'!bg-amber-700',
	'!bg-green-600',
	'!bg-cyan-600',
	'!bg-indigo-400',
	'!bg-fuchsia-400',
	'!bg-zinc-400'
];

const hashId = (id: string) => {
	let hash = 0;
	for (let i = 0; i < id.length; i++) {
		hash = (hash << 5) - hash + id.charCodeAt(i);
	}
	return hash;
};

// Function to get a deterministic random color based on the ID
const getColorFromId = (id: string) => {
	const hashValue = hashId(id); // Get hash from ID
	const colorIndex = Math.abs(hashValue) % tailwindColors.length; // Map hash value to a color index
	return tailwindColors[colorIndex];
};

interface RandomColorBadgeProps {
	idToHash: string;
	children: React.ReactNode;
}
/**
 * Returns colored badge based on passed idToHash prop
 */
export const RandomColorBadge = ({ idToHash, children }: RandomColorBadgeProps) => {
	return (
		<Badge size="small" className={clsx(getColorFromId(idToHash), 'text-white')}>
			{children}
		</Badge>
	);
};
