import { Interceptors } from '@/sdk/react';
import { ApiError } from '@sdk/lib';
import { isNil } from 'lodash-es';
import { refreshOrSignOut, signOut } from '../auth';

export const shouldRetry = (failureCount: number, error: Error) => {
	const is4xx = error instanceof ApiError && error.status >= 400 && error.status <= 499;
	const allowedAttempts = is4xx ? 1 : 3;

	return failureCount < allowedAttempts;
};

export const onError = async (error: Error) => {
	if (error instanceof ApiError) {
		if (error.status === 401) {
			signOut();
		}
	}
};

export const interceptors: Interceptors = {
	onError: async (error: unknown) => {
		const is401 =
			(error instanceof ApiError && error.status == 401) ||
			(typeof error === 'object' &&
				!isNil(error) &&
				('Unauthorized' in error || 'unauthorized' in error));

		if (!is401) {
			return false;
		}

		return await refreshOrSignOut();
	}
};
